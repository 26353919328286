import { Dialog } from 'quasar'

import loginComponent from 'src/components/auth/login.vue'
import { useAuthStore } from 'src/stores/auth.store'

export function useAuth() {
	const authStore = useAuthStore()
	function logout() {
		authStore.logout().then(() => {
			// TODO: cannot use useRouter in composable
			window.location.pathname = '/'
			localStorage.clear()
		})
	}

	function login(): Promise<'ok' | 'closed'> {
		return new Promise((resolve) => {
			Dialog.create({
				component: loginComponent,
			})
				.onOk(() => {
					resolve('ok')
				})
				.onCancel(() => {
					resolve('closed')
				})
		})
	}

	return { login, logout }
}
