import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
	EmailSignUpInputRoleEnum,
	ProfileLanguageEnum,
} from 'src/common/axios-client'
import languageSelect from '../personal/language-select.vue'
import timezoneSelect from '../personal/timezone-select.vue'
import { SelectOptions } from 'src/interfaces/select'
import { useRefsStore } from 'src/stores/refs.store'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'
import { useRouter } from 'vue-router'
export type RegisterForm = {
	language: ProfileLanguageEnum
	nickname: string
	role: EmailSignUpInputRoleEnum
	timezone: string
	countryId: number
}
type Props = {
	submit: (form: any) => Promise<unknown>
	extraData?: Record<string, unknown>
	buttonLabel: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'register-form',
  props: {
    submit: { type: Function },
    extraData: {},
    buttonLabel: {}
  },
  setup(__props: any) {

const i18n = useI18n()
const refsStore = useRefsStore()

const props = __props
const router = useRouter()

const roleOptions = ref<SelectOptions>([])
const form = ref<RegisterForm>({
	language: ProfileLanguageEnum.Ru,
	nickname: '',
	role: EmailSignUpInputRoleEnum.Customer,
	timezone: 'Europe/Moscow',
	countryId: 185,
})

const formValid = computed(() => {
	return z.string().min(1).safeParse(form.value.nickname).success
})

const countryOptions = ref<SelectOptions>([])
const isLoading = ref(false)

watch([form.value], () => {
	if (i18n.locale.value !== form.value.language) {
		i18n.locale.value = form.value.language
		updateLanguage()
	}
})
updateLanguage()

function updateLanguage() {
	refsStore.countries(form.value.language).then((countries) => {
		const list: SelectOptions = countries.map((country) => ({
			label: country.name,
			value: country.id,
		}))
		countryOptions.value = list
	})

	roleOptions.value = [
		{
			label: i18n.t('usertype.customer'),
			value: EmailSignUpInputRoleEnum.Customer,
		},
		{ label: i18n.t('usertype.user'), value: EmailSignUpInputRoleEnum.User },
	]
}

function submitForm() {
	isLoading.value = true
	const payload = {
		...form.value,
		...props.extraData,
	}
	props
		.submit(payload)
		.then(() => {
			router.push('/')
		})
		.finally(() => {
			isLoading.value = false
		})
}

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, {
    onSubmit: submitForm,
    onKeydown: _withKeys(submitForm, ["enter"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "text-center flex column q-gutter-sm" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_q_input, {
            modelValue: form.value.nickname,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.nickname) = $event)),
            modelModifiers: { trim: true },
            label: _unref(i18n).t('login.nickname'),
            "hide-bottom-space": "",
            clearable: "",
            autocapitalize: "off"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_q_select, {
            modelValue: form.value.countryId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.countryId) = $event)),
            "float-label": "Country",
            label: _unref(i18n).t('login.country'),
            radio: "",
            options: countryOptions.value,
            filter: true,
            "emit-value": "",
            "map-options": ""
          }, null, 8, ["modelValue", "label", "options"]),
          _createVNode(languageSelect, {
            modelValue: form.value.language,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.language) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(timezoneSelect, {
            modelValue: form.value.timezone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.timezone) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_q_btn_toggle, {
            spread: "",
            options: roleOptions.value,
            modelValue: form.value.role,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.role) = $event))
          }, null, 8, ["options", "modelValue"])
        ]),
        _: 3
      }),
      _createVNode(_component_q_card_actions, { align: "evenly" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            class: "full-width",
            label: props.buttonLabel,
            loading: isLoading.value,
            color: "primary",
            onClick: submitForm,
            disable: !formValid.value
          }, null, 8, ["label", "loading", "disable"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}
}

})