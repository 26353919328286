import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'
import { z } from 'zod'

type Props = {
	label?: string
	validation?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'password-input',
  props: {
    label: { default: 'Пароль' },
    validation: { type: Boolean, default: true }
  },
  emits: ['input'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const schema = z
	.string()
	.min(6, { message: 'Пароль должен быть длиннее 6 символов' })

const password = ref('')
const passwordVisible = ref(false)
const isValid = computed(() => schema.safeParse(password.value).success)
const error = computed(() =>
	schema.safeParse(password.value).error?.formErrors.formErrors.shift(),
)

const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: password.value,
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
      _cache[2] || (_cache[2] = ($event: any) => (emit('input', password.value)))
    ],
    modelModifiers: { trim: true },
    type: passwordVisible.value ? 'text' : 'password',
    label: props.label,
    autocapitalize: "off",
    "bottom-slots": "",
    "error-message": error.value,
    error: props.validation && password.value !== '' && !isValid.value
  }, {
    append: _withCtx(() => [
      _createVNode(_component_q_btn, {
        flat: "",
        "fab-mini": "",
        icon: passwordVisible.value ? 'ion-eye' : 'ion-eye-off',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (passwordVisible.value = !passwordVisible.value))
      }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["modelValue", "type", "label", "error-message", "error"]))
}
}

})