import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "flex row" }
const _hoisted_3 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "flex row" }
const _hoisted_6 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_7 = { class: "header" }
const _hoisted_8 = { class: "flex row" }
const _hoisted_9 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_10 = { class: "header" }
const _hoisted_11 = { class: "flex row full-width" }
const _hoisted_12 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}
const _hoisted_13 = { class: "header" }
const _hoisted_14 = { class: "flex row" }
const _hoisted_15 = {
  class: "flex row justify-center",
  style: {"flex-grow":"1"}
}

import personCard from 'src/components/public/person-card.vue'
import salonCard from 'src/components/public/salon-card.vue'
import intimeMap from 'src/components/public/intime-map.vue'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useUiStore } from 'stores/ui.store'
import { Slugs } from 'pages/public/slug.map'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  ...{
	async preFetch() {
		const prefetchStore = usePreFetchStore()
		return prefetchStore.update()
	},
},
  __name: 'main-page',
  setup(__props) {

const { t } = useI18n()

const uiStore = useUiStore()
const { city, district } = storeToRefs(uiStore)

const prefetchStore = usePreFetchStore()

const { salons, persons, newPersons, top20Persons, massagePersons } =
	storeToRefs(prefetchStore)



const salonViewAllRoute = computed(() => {
	if (district.value)
		return `/salons/${Slugs.CITY}/${city.value.slug}/${Slugs.DISTRICT}/${district.value.slug}`
	return `/salons/${Slugs.CITY}/${city.value.slug}`
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "flex column gap-30" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _createTextVNode(_toDisplayString(_unref(t)('mainPage.header.title')) + " ", 1),
        _createElementVNode("sup", null, [
          _createVNode(_component_q_icon, {
            name: "trip_origin",
            size: "xs"
          }),
          _createTextVNode(" 882 " + _toDisplayString(_unref(t)('mainPage.header.online')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(persons), (person) => {
          return (_openBlock(), _createBlock(personCard, {
            key: person.id,
            person: person,
            class: "col-lg-3 col-md-3 col-sm-6 col-xs-12 q-px-sm q-py-md"
          }, null, 8, ["person"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            class: "show-all-btn",
            label: _unref(t)('mainPage.allButton')
          }, null, 8, ["label"])
        ])
      ]),
      _createVNode(intimeMap),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(t)('mainPage.new')), 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(newPersons), (person) => {
          return (_openBlock(), _createBlock(personCard, {
            key: person.id,
            person: person,
            class: "col-lg-3 col-md-3 col-sm-6 col-xs-12 q-px-sm q-py-md"
          }, null, 8, ["person"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            class: "show-all-btn",
            label: _unref(t)('mainPage.allButton')
          }, null, 8, ["label"])
        ])
      ]),
      _createElementVNode("h1", _hoisted_7, _toDisplayString(_unref(t)('mainPage.top20')), 1),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(top20Persons), (person) => {
          return (_openBlock(), _createBlock(personCard, {
            key: person.id,
            person: person,
            class: "col-lg-3 col-md-3 col-sm-6 col-xs-12 q-px-sm q-py-md"
          }, null, 8, ["person"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            class: "show-all-btn",
            label: _unref(t)('mainPage.allButton')
          }, null, 8, ["label"])
        ])
      ]),
      (_unref(salons).length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h1", _hoisted_10, _toDisplayString(_unref(t)('mainPage.salons')), 1),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(salons), (salon) => {
                return (_openBlock(), _createBlock(salonCard, {
                  class: "col-lg-3 col-md-3 col-sm-6 col-xs-12 q-px-sm q-py-md",
                  key: salon.id,
                  salon: salon
                }, null, 8, ["salon"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  class: "show-all-btn",
                  label: _unref(t)('mainPage.allButton'),
                  to: salonViewAllRoute.value
                }, null, 8, ["label", "to"])
              ])
            ])
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_13, _toDisplayString(_unref(t)('mainPage.massage')), 1),
      _createElementVNode("div", _hoisted_14, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(massagePersons), (person) => {
          return (_openBlock(), _createBlock(personCard, {
            key: person.id,
            person: person,
            class: "col-lg-3 col-md-3 col-sm-6 col-xs-12 q-px-sm q-py-md"
          }, null, 8, ["person"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            class: "show-all-btn",
            label: _unref(t)('mainPage.allButton')
          }, null, 8, ["label"])
        ])
      ]),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mainPage.p1')), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mainPage.p2')), 1),
      _createElementVNode("h2", null, _toDisplayString(_unref(t)('mainPage.howto.title')), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mainPage.howto.p1')), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mainPage.howto.p2')), 1)
    ]),
    _: 1
  }))
}
}

})