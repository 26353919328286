type PersonContact = 'phone' | 'telegram' | 'whatsapp'
type SalonContact = 'phone' | 'telegram' | 'whathapp' | 'email' | 'web'

export function useAnalytics() {
	function personContactClick(personId: number, type: PersonContact): void {
		console.log(`Person ${personId} click: ${type}`)
	}

	function salonContactClick(salonId: number, type: SalonContact): void {
		console.log(`Salon ${salonId} click: ${type}`)
	}

	return { personContactClick, salonContactClick }
}
