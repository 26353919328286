import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-px-lg q-pb-md text-center" }

import { useI18n } from 'vue-i18n'
import LoginByEmail from './login-by-email.vue'
import register, { RegisterType } from './register.vue'
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'

export type Tab = 'sign-in' | 'sign-up'


export default /*@__PURE__*/_defineComponent({
  __name: 'login',
  emits: useDialogPluginComponent.emits,
  setup(__props) {

const i18n = useI18n()

const tab = ref<Tab>('sign-in')
const tabType = ref<RegisterType>('email')
const dialog = ref(true)

function setTab(type: RegisterType) {
	tab.value = 'sign-up'
	tabType.value = type
}

const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent()



function okClose() {
	dialog.value = false
	onDialogOK()
}

function cancelClose() {
	dialog.value = false
	onDialogHide()
}

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    modelValue: dialog.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((dialog).value = $event)),
    "backdrop-filter": "blur(2px)",
    persistent: "",
    "no-backdrop-dismiss": true,
    "no-esc-dismiss": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, { class: "bg-primary text-dark q-pa-none justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tabs, {
                modelValue: tab.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tab).value = $event)),
                align: "justify",
                class: "full-width"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tab, {
                    label: _unref(i18n).t('login.tabTitle'),
                    name: "sign-in"
                  }, null, 8, ["label"]),
                  _createVNode(_component_q_tab, {
                    label: _unref(i18n).t('login.tabRegistration'),
                    name: "sign-up"
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panels, {
            modelValue: tab.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((tab).value = $event)),
            animated: "",
            "keep-alive": "",
            swipeable: "",
            infinite: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab_panel, { name: "sign-in" }, {
                default: _withCtx(() => [
                  _createVNode(LoginByEmail, {
                    onTab: _cache[1] || (_cache[1] = ($event: any) => (setTab($event))),
                    onDialogClose: _cache[2] || (_cache[2] = ($event: any) => (okClose()))
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_tab_panel, { name: "sign-up" }, {
                default: _withCtx(() => [
                  _createVNode(register, {
                    type: tabType.value,
                    onDialogClose: _cache[3] || (_cache[3] = ($event: any) => (okClose()))
                  }, null, 8, ["type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_q_separator, { class: "q-mb-md" }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_q_btn, {
              flat: "",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (cancelClose())),
              label: _unref(i18n).t('login.close')
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})