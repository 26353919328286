import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, unref as _unref, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "row gap-10 no-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "tags absolute-top-right gap-2"
}
const _hoisted_3 = { class: "tags absolute-top-left" }
const _hoisted_4 = { class: "flex row tags absolute-bottom-right gap-2" }
const _hoisted_5 = { class: "q-ml-xs" }
const _hoisted_6 = { class: "column full-width gap-5" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "q-ml-auto" }
const _hoisted_9 = { class: "flex row gap-2" }
const _hoisted_10 = { class: "row q-mt-sm" }
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "address" }
const _hoisted_14 = { class: "row buttons gap-5 full-width" }
const _hoisted_15 = { class: "flex row prices q-py-sm" }
const _hoisted_16 = {
  key: 0,
  class: "row col-6 col-xs-12 q-mr-xs q-mb-xs"
}
const _hoisted_17 = { class: "q-ml-auto rub self-center full-height" }
const _hoisted_18 = {
  key: 1,
  class: "row col-6 col-xs-12 q-mb-xs"
}
const _hoisted_19 = { class: "q-ml-auto rub self-center full-height" }
const _hoisted_20 = {
  key: 2,
  class: "row col-6 col-xs-12 q-mr-xs q-mb-xs"
}
const _hoisted_21 = { class: "row q-ml-auto rub self-center full-height" }
const _hoisted_22 = {
  key: 3,
  class: "row col-6 col-xs-12 q-mb-xs"
}

import { PersonContacts, PublicPersonDtoItem } from 'src/common/axios-client'
import poleeIcon from '../common/polee-icon.vue'
import rankIcon from '../common/rank-icon.vue'
import personTag from './person/person-tag.vue'
import healthIcon from './health-icon.vue'
import { computed, onMounted, ref } from 'vue'
import { Slugs } from 'src/pages/public/slug.map'
import telegramButton from '../common/telegram-button.vue'
import whatsappButton from '../common/whatsapp-button.vue'
import phoneButton from '../common/phone-button.vue'
import favoriteBtn from '../common/favorite-btn.vue'
import { formatPrice } from 'src/functions/format'
import { API } from 'src/boot/axios'
import { IMedia } from '@interfaces/media.interface'
import { useMediaStore } from 'src/stores/media.store'
import { useI18n } from 'vue-i18n'

type Props = {
	person: PublicPersonDtoItem
}


export default /*@__PURE__*/_defineComponent({
  __name: 'person-card',
  props: {
    person: {}
  },
  setup(__props: any) {

const props = __props
const { t } = useI18n()

const mediaStore = useMediaStore()
const contactsLoading = ref(false)
const contacts = ref<PersonContacts>()

const properties = computed(() =>
	props.person.properties.filter((p) => p.property.isCardProperty),
)
const media = props.person.media as IMedia[]
const image = media.find((e) => e.kind === 'image')

const imageUrl = ref<string>()

onMounted(() => {
	if (image)
		mediaStore.getImageUrl(image.id, 'card').then((url) => {
			imageUrl.value = url
		})
})

const prices = {
	day: {
		'1hour': props.person.prices.day.find((e) => e.hours === 1)?.priceAtUser,
		'2hours': props.person.prices.day.find((e) => e.hours === 2)?.priceAtUser,
	},
	night: {
		night: props.person.prices.night.find((e) => e.hours === null)?.priceAtUser,
	},
	express: props.person.hasExpress ?? false,
}

function showPhone() {
	contactsLoading.value = true
	API.contactsApi
		.personContactControllerGetContactsById(props.person.id)
		.then((r) => {
			contacts.value = r.data
		})
		.finally(() => {
			contactsLoading.value = false
		})
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "flex column card" }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: `/${props.person.id}`,
          class: "full-width"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_img, {
              loading: "lazy",
              class: "image",
              position: "0 0",
              src: imageUrl.value,
              "no-native-menu": "",
              fetchpriority: "high"
            }, {
              default: _withCtx(() => [
                (props.person.top20)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(rankIcon, {
                        rank: props.person.top20
                      }, null, 8, ["rank"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(favoriteBtn, {
                    "btn-style": "icon",
                    "person-id": props.person.id
                  }, null, 8, ["person-id"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (props.person.marks.video)
                    ? (_openBlock(), _createBlock(_component_q_chip, {
                        key: 0,
                        class: "tag"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, { name: "play_arrow" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (props.person.marks.comments)
                    ? (_openBlock(), _createBlock(_component_q_chip, {
                        key: 1,
                        class: "tag"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, { name: "comment" }),
                          _createElementVNode("span", _hoisted_5, _toDisplayString(props.person.marks.comments), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["src"])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(properties.value, (property) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex row property",
            key: property.property.id
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(property.property.name), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(property.number), 1)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.person.tags, (tag) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tag.id
            }, [
              _createVNode(personTag, { tag: tag }, null, 8, ["tag"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_router_link, {
        to: `/${props.person.id}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            (props.person.healthStatus?.isHealthy)
              ? (_openBlock(), _createBlock(healthIcon, {
                  key: 0,
                  size: "xs"
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(props.person.name), 1)
          ])
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_router_link, {
          to: `/${_unref(Slugs).CITY}/${props.person.city.slug}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.person.city.name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        (props.person.district)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[1] || (_cache[1] = _createTextVNode(", ")),
              _createVNode(_component_router_link, {
                to: `/${_unref(Slugs).CITY}/${props.person.city.slug}/${_unref(Slugs).DISTRICT}/${props.person.district.slug}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.person.district.name), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_q_no_ssr, { class: "full-width q-mt-sm" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_14, [
          (!contacts.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                class: "button button-phone",
                flat: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showPhone())),
                loading: contactsLoading.value,
                icon: "call",
                label: _unref(t)('personCard.phoneButtonTitle')
              }, null, 8, ["loading", "label"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (contacts.value.phone)
                  ? (_openBlock(), _createBlock(phoneButton, {
                      key: 0,
                      class: "button button-phone col-12",
                      "person-id": _ctx.person.id,
                      phone: contacts.value.phone
                    }, null, 8, ["person-id", "phone"]))
                  : _createCommentVNode("", true),
                (contacts.value.polee)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      flat: "",
                      class: "button-polee col-grow",
                      style: {"flex-grow":"20"},
                      href: `https://polee/${contacts.value.polee}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(poleeIcon, { class: "q-mr-xs" }),
                        _cache[2] || (_cache[2] = _createTextVNode(" Polee "))
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : _createCommentVNode("", true),
                (contacts.value?.telegram)
                  ? (_openBlock(), _createBlock(telegramButton, {
                      key: 2,
                      class: "button button-icon col-grow",
                      "person-id": _ctx.person.id,
                      telegram: contacts.value.telegram,
                      "btn-style": "flat"
                    }, null, 8, ["person-id", "telegram"]))
                  : _createCommentVNode("", true),
                (contacts.value?.whatsapp)
                  ? (_openBlock(), _createBlock(whatsappButton, {
                      key: 3,
                      class: "button button-icon col-grow",
                      "person-id": _ctx.person.id,
                      whatsapp: contacts.value.whatsapp,
                      "btn-style": "flat"
                    }, null, 8, ["person-id", "whatsapp"]))
                  : _createCommentVNode("", true)
              ], 64))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_15, [
      (prices.day['1hour'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_icon, {
                size: "xs",
                name: "alarm"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('personCard.prices.oneHour')), 1)
            ]),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(formatPrice)(prices.day['1hour'])), 1)
          ]))
        : _createCommentVNode("", true),
      (prices.day['2hours'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_icon, {
                size: "xs",
                name: "alarm"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('personCard.prices.twoHour')), 1)
            ]),
            _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(formatPrice)(prices.day['2hours'])), 1)
          ]))
        : _createCommentVNode("", true),
      (prices.night['night'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_icon, {
                size: "xs",
                name: "nights_stay"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('personCard.prices.night')), 1)
            ]),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(formatPrice)(prices.night['night'])), 1)
          ]))
        : _createCommentVNode("", true),
      (prices.express)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_icon, {
                size: "xs",
                name: "speed"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('personCard.prices.hasExpress')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 16))
}
}

})