import { defineStore, StateTree } from 'pinia'
import { API } from 'src/boot/axios'
import { computed, ref, watch } from 'vue'
import { useAuthStore } from './auth.store'
import { FavoritePerson } from 'src/common/axios-client'
import { isAfter } from 'date-fns'

export const useFavoriteStore = defineStore(
	'favoriteStore',
	() => {
		const authStore = useAuthStore()
		const favoritePersons = ref(new Map<number, FavoritePerson>())

		watch(
			() => authStore.user,
			() => {
				if (authStore.user) {
					sync()
				} else {
					clear()
				}
			},
		)

		const values = computed(() => {
			return Array.from(favoritePersons.value.values()).sort((a, b) =>
				isAfter(a.createdAt, b.createdAt) ? 1 : -1,
			)
		})

		function addToStore(p: FavoritePerson): void {
			favoritePersons.value.set(p.personId, p)
		}

		function rmFromStore(personId: number): void {
			favoritePersons.value.delete(personId)
		}

		async function sync(): Promise<void> {
			// sync favorites with local store

			await API.favoritesApi.favoritesControllerFindAll().then((r) => {
				const favs = r.data

				favs.forEach((i) => {
					addToStore(i)
				})

				favoritePersons.value.forEach((fav) => {
					const isFav = favs.some((f) => f.personId === fav.personId)
					if (!isFav) add(fav.personId)
				})
			})
		}

		async function add(personId: number): Promise<void> {
			if (!authStore.user) {
				addToStore({
					personId,
					createdAt: new Date().toISOString(),
				})

				return
			}

			return API.favoritesApi.favoritesControllerCreate(personId).then((r) => {
				addToStore(r.data)
			})
		}

		async function rm(personId: number): Promise<void> {
			if (!authStore.user) {
				rmFromStore(personId)
				return
			}

			return API.favoritesApi.favoritesControllerDelete(personId).then(() => {
				rmFromStore(personId)
			})
		}

		function clear(): void {
			favoritePersons.value.clear()
		}

		function isFavorite(personId: number): boolean {
			return favoritePersons.value.has(personId)
		}

		function count(): number {
			return favoritePersons.value.size
		}

		const favoriteLabel = computed(() => {
			if (count() < 100) return count().toString()
			return '99+'
		})

		if (authStore.user) sync()

		return {
			favoritePersons,
			favoriteLabel,
			values,
			sync,
			count,
			isFavorite,
			add,
			rm,
			clear,
		}
	},
	{
		persist: {
			beforeRestore(context) {
				context.store.favoritePersons = new Map()
			},
			serializer: {
				deserialize: parse,
				serialize: stringify,
			},
		},
	},
)

function parse(value: string): StateTree {
	return JSON.parse(value, (key, value) => {
		if (typeof value === 'object' && value !== null && value.isMap)
			return new Map(value.value)
		return value
	})
}

function stringify(value: unknown): string {
	return JSON.stringify(value, (key, value) => {
		if (value instanceof Map) {
			return { isMap: true, value: Array.from(value.entries()) }
		}
		return value
	})
}
