export type ValidationError = Record<string, string[]>

export type ErrorMessage = string | ValidationError

export interface BackendError {
	message: ErrorMessage
	stack?: any
}

/** checks is ErrorMessage is ValidationError */
export function isValidationError(
	error: ErrorMessage,
): error is ValidationError {
	return !isStringError(error)
}

/** checks is ErrorMessage is string */
export function isStringError(error: ErrorMessage): error is string {
	return typeof error === 'string'
}
