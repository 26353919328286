import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href", "title"]
const _hoisted_2 = { class: "absolute-full flex flex-center bg-grey-1 text-black" }

import { Banner } from 'src/common/axios-client'
import { Screen } from 'quasar'
import { computed } from 'vue'

type Props = {
	banner: Banner
}


export default /*@__PURE__*/_defineComponent({
  __name: 'banner-item',
  props: {
    banner: {}
  },
  setup(__props: any) {

const props = __props

const src = computed(() => {
	if (Screen.gt.xs) return props.banner.imageUrl2x
	return props.banner.imageUrl
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("a", {
    href: props.banner.link,
    target: "_blank",
    title: props.banner.seoText ?? undefined
  }, [
    _createVNode(_component_q_card, {
      class: "banner",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_img, {
          loading: "lazy",
          class: "image",
          alt: props.banner.seoText ?? undefined,
          src: src.value,
          fit: "cover",
          "no-native-menu": ""
        }, {
          error: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_badge, {
                rounded: "",
                class: "bg-grey-1 text-red absolute-top-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "error",
                    color: "red"
                  })
                ]),
                _: 1
              }),
              (props.banner.seoText)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(props.banner.seoText), 1)
                  ], 64))
                : (_openBlock(), _createBlock(_component_q_icon, {
                    key: 1,
                    name: "image",
                    size: "lg",
                    color: "grey"
                  }))
            ])
          ]),
          _: 1
        }, 8, ["alt", "src"])
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}
}

})