import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = {
  class: "row no-wrap q-pa-xs",
  style: {"overflow":"hidden"}
}
const _hoisted_3 = { class: "column dropdown-column" }
const _hoisted_4 = { class: "column items-center dropdown-column" }

import { storeToRefs } from 'pinia'
import { City, MenuCity, MetroStation } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'
import { useMenuStore } from 'src/stores/menu.store'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { useUiStore } from 'src/stores/ui.store'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'metro-selector',
  setup(__props) {

const uiStore = useUiStore()

const { close, isOpen, toggle } = useMenuStore()

const prefetchStore = usePreFetchStore()
const { t } = useI18n()

const { metro } = storeToRefs(uiStore)

const { menuCities } = storeToRefs(prefetchStore)

const activeCity = ref<City>(menuCities.value[0] ?? null)

const metroSearch = ref<string | null>(null)

async function selectCity(city: City) {
	activeCity.value = city
}

function selectMetroStation(metroStation: MetroStation | null) {
	close('metro')
	uiStore.setCity(activeCity.value)
	uiStore.setMetroStation(metroStation)
}

function filteredMetroStations(ms: MenuCity['metroStations']) {
	const msSearch = metroSearch.value
	if (!msSearch) return ms
	if (msSearch.trim().length === 0) return ms

	return ms.filter((m) => m.name.toLowerCase().includes(msSearch.toLowerCase()))
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      rounded: "",
      style: {"max-height":"460px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggle)('metro'))),
      class: _normalizeClass([{ selected: _unref(isOpen)('metro') }, "metro-btn"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "ion-train",
          class: "q-mr-xs"
        }),
        _createTextVNode(" " + _toDisplayString(_unref(metro) ? _unref(metro).i18.text : _unref(t)('labels.metro')), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities).filter(
								(c) => c.metroStations.length !== 0,
							), (city) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                      key: city.id,
                      clickable: "",
                      class: _normalizeClass(["city-selector-item", {
								'selected-city': city.id === activeCity.value.id,
							}]),
                      onClick: ($event: any) => (selectCity(city))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(city.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_q_separator, {
          vertical: "",
          inset: "",
          class: "q-mx-xs"
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city) => {
                return _withDirectives((_openBlock(), _createBlock(_component_q_list, {
                  key: city.id
                }, {
                  default: _withCtx(() => [
                    (city.metroStations.length !== 0)
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 0,
                          class: "city-selector-item"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                              modelValue: metroSearch.value,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((metroSearch).value = $event)),
                              dense: "",
                              label: _unref(t)('metroSelector.search'),
                              clearable: ""
                            }, null, 8, ["modelValue", "label"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_router_link, {
                      to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          class: "city-selector-item",
                          onClick: _cache[2] || (_cache[2] = 
									() => {
										_unref(uiStore).setMetroStation(null)
										_unref(close)('metro')
									}
								)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('metroSelector.allStations')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredMetroStations(city.metroStations), (metroStation) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        key: metroStation.id,
                        to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}/${_unref(Slugs).METRO_STATION}/${metroStation.slug}`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item, {
                            clickable: "",
                            class: "city-selector-item",
                            onClick: ($event: any) => (selectMetroStation(metroStation))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(metroStation.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)), [
                  [_vShow, city.id === activeCity.value.id]
                ])
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)('metro')]
    ])
  ], 64))
}
}

})