import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useAnalytics } from 'src/composables/analytics'

type Props = {
	personId: number
	whatsapp: string
	withLabel?: boolean
	btnStyle: 'outline' | 'flat'
}

export default /*@__PURE__*/_defineComponent({
  __name: 'whatsapp-button',
  props: {
    personId: {},
    whatsapp: {},
    withLabel: { type: Boolean },
    btnStyle: {}
  },
  setup(__props: any) {

const props = __props

const { personContactClick } = useAnalytics()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    icon: "fa-brands fa-whatsapp",
    label: props.withLabel ? 'Whatsapp' : undefined,
    href: `https://wa.me/${props.whatsapp}`,
    outline: props.btnStyle === 'outline',
    flat: props.btnStyle === 'flat',
    target: "_blank",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(personContactClick)(props.personId, 'whatsapp')))
  }, null, 8, ["label", "href", "outline", "flat"]))
}
}

})