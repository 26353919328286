import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { TelegramProviderCreateInput } from 'src/common/axios-client'
import { useAuthStore } from 'src/stores/auth.store'
import { telegramLoginTemp } from 'vue3-telegram-login'

interface TelegramCallbackData {
	id: number
	first_name?: string
	last_name?: string
	username?: string
	photo_url?: string
	auth_date: number
	hash: string
}

type Props = {
	apiFn: (obj: TelegramProviderCreateInput) => Promise<unknown>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'telegram-auth-btn',
  props: {
    apiFn: { type: Function }
  },
  emits: ['add'],
  setup(__props: any, { emit: __emit }) {

const BOT_NAME = process.env.TELEGRAM_BOT_NAME

const props = __props
const emit = __emit
const authStore = useAuthStore()

async function login(payload: TelegramCallbackData) {
	const converted = convertPayload(payload)
	authStore.telegramData = converted
	props.apiFn(converted).then(() => {
		emit('add', true)
	})
}

function convertPayload(d: TelegramCallbackData): TelegramProviderCreateInput {
	return {
		authDate: d.auth_date,
		hash: d.hash,
		id: d.id,
		firstname: d.first_name,
		lastname: d.last_name,
		photoUrl: d.photo_url,
		username: d.username,
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createBlock(_component_q_no_ssr, null, {
    default: _withCtx(() => [
      _createVNode(_unref(telegramLoginTemp), {
        mode: "callback",
        "telegram-login": _unref(BOT_NAME),
        onCallback: login,
        size: "medium",
        userpic: "false",
        redius: "5",
        lang: "ru"
      }, null, 8, ["telegram-login"])
    ]),
    _: 1
  }))
}
}

})