import { RouteRecordRaw } from 'vue-router'

export default [
	{
		path: '/auth',
		component: () => import('layouts/main-layout.vue'),
		children: [
			{
				path: 'password-reset',
				component: () => import('pages/auth/password-reset.vue'),
			},
			{
				path: 'verify-email',
				component: () => import('pages/auth/verify-email.vue'),
			},
		],
	},
] satisfies RouteRecordRaw[]
