export function formatPrice(price: number): string {
	return price.toLocaleString('ru')
}

export function formatPhone(phone: string): string {
	// const p = '+7  915 064 78 02'
	if (!phone.startsWith('+')) {
		phone = `+${phone}`
	}
	const l = phone.length
	const countryCode = phone.substring(0, l - 10)
	const l1 = phone.substring(l - 2, l)
	const l2 = phone.substring(l - 4, l - 2)
	const l3 = phone.substring(l - 7, l - 4)
	const c2 = phone.substring(l - 10, l - 7)
	return `${countryCode} (${c2}) ${l3} ${l2} ${l1}`
}

export function normalizePhone(phone: string): string {
	if (!phone.startsWith('+')) return `+${phone}`
	return phone
}
