import { defineStore, storeToRefs } from 'pinia'
import {
	Category,
	Feature,
	MenuCity,
	PublicPersonDtoItem,
	PublicSalonDto,
} from 'src/common/axios-client/api'
import { API } from 'boot/axios'
import { DEFAULT_COUNTRY_ID } from 'src/constants'
import { getPaginationSeed } from 'src/common/seed'
import { computed, ref, watch } from 'vue'
import { useUiStore } from './ui.store'

export const usePreFetchStore = defineStore('prefetchStore', () => {
	const features = ref<Feature[]>([])
	const categories = ref<Category[]>([])
	const menuCities = ref<MenuCity[]>([])
	const salons = ref<PublicSalonDto[]>([])
	const persons = ref<PublicPersonDtoItem[]>([])
	const newPersons = ref<PublicPersonDtoItem[]>([])
	const top20Persons = ref<PublicPersonDtoItem[]>([])
	const massagePersons = ref<PublicPersonDtoItem[]>([])
	const seed = ref(getPaginationSeed())

	const uiStore = useUiStore()
	const { locale, city, district, metro } = storeToRefs(uiStore)

	const isCityHasMetro = computed(() => {
		const _city = menuCities.value.find((mc) => mc.id === city.value.id)
		if (!_city) return false
		return _city.metroStations.length > 0
	})

	watch([locale, city, district, metro], () => {
		update()
	})

	async function update(): Promise<void> {
		await Promise.all([
			updateFeaturesStore(),
			updateCategoriesStore(),
			updateCities(),
			updateSalons(),
			updatePersons(),
			updateNewPersons(),
			updateTop20Persons(),
			updateMassagePersons(),
		])
	}

	async function updateFeaturesStore(): Promise<void> {
		await API.refsApi.menuControllerCatalogFeatures().then((e) => {
			features.value = e.data
		})
	}

	async function updateCategoriesStore(): Promise<void> {
		await API.refsApi.refsControllerCategories().then((r) => {
			categories.value = r.data
		})
	}

	async function updateCities(): Promise<void> {
		await API.refsApi
			.menuControllerCities(undefined, undefined, DEFAULT_COUNTRY_ID)
			.then(async (r) => {
				menuCities.value = r.data
			})
	}

	async function updateSalons(): Promise<void> {
		await API.salonsApi
			.salonControllerFindAll(
				4,
				0,
				seed.value,
				undefined,
				city.value.id,
				district.value?.id,
				metro.value ? [metro.value.id] : undefined,
			)
			.then((r) => {
				salons.value = r.data.items
			})
	}
	async function updatePersons(): Promise<void> {
		// TODO: update filter
		await API.personsApi
			.personControllerFindAll(8, 0, seed.value, undefined, city.value.id)
			.then((r) => {
				persons.value = r.data.items
			})
	}
	async function updateNewPersons(): Promise<void> {
		// TODO: update filter
		await API.personsApi
			.personControllerFindAll(4, 0, seed.value, undefined, city.value.id)
			.then((r) => {
				newPersons.value = r.data.items
			})
	}
	async function updateTop20Persons(): Promise<void> {
		// TODO: update filter
		await API.personsApi
			.personControllerFindAll(4, 0, seed.value, undefined, city.value.id)
			.then((r) => {
				top20Persons.value = r.data.items
			})
	}
	async function updateMassagePersons(): Promise<void> {
		// TODO: update filter
		await API.personsApi
			.personControllerFindAll(4, 0, seed.value, undefined, city.value.id)
			.then((r) => {
				massagePersons.value = r.data.items
			})
	}
	return {
		features,
		categories,
		menuCities,
		isCityHasMetro,
		salons,
		persons,
		newPersons,
		top20Persons,
		massagePersons,
		update,
	}
})
