import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ProfileLanguageEnum } from 'src/common/axios-client'
import { SelectOption, SelectOptions } from 'src/interfaces/select'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import timezones from 'timezones-list'

type Props = {
	initial?: string
	language?: ProfileLanguageEnum
}

export default /*@__PURE__*/_defineComponent({
  __name: 'timezone-select',
  props: {
    initial: { default: undefined },
    language: { default: ProfileLanguageEnum.Ru }
  },
  emits: ['input'],
  setup(__props: any, { emit: __emit }) {

const i18n = useI18n()

const timezoneOptions: SelectOptions = timezones.map((tz) => ({
	label: tz.tzCode,
	value: tz.tzCode,
}))

const selectedValue = ref<SelectOption>()

const props = __props
const emit = __emit

if (props.initial) {
	selectedValue.value = timezoneOptions.find((e) => e.value === props.initial)
}

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: selectedValue.value,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
      _cache[1] || (_cache[1] = ($event: any) => (emit('input', selectedValue.value?.value)))
    ],
    "float-label": "Timezone",
    label: _unref(i18n).t('login.timezone'),
    options: _unref(timezoneOptions),
    "emit-value": "",
    filter: true,
    radio: ""
  }, null, 8, ["modelValue", "label", "options"]))
}
}

})