import { defineStore } from 'pinia'
import { TinyCountry } from 'src/common/axios-client/api'
import { API } from 'boot/axios'

export const useRefsStore = defineStore('refsStore', {
	state: () => ({}),

	getters: {},
	actions: {
		countries(): Promise<TinyCountry[]> {
			return API.refsApi
				.refsControllerCountries()
				.then((e) => {
					return e.data
				})
				.catch(() => {
					return []
				})
		},
	},
})
