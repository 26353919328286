import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { useAuthStore } from 'stores/auth.store'
import { computed, ref } from 'vue'
import emailInput from 'src/components/common/tools/email-input.vue'
import registerForm from 'src/components/common/register-form.vue'
import {
	EmailSignUpInput,
	GoogleSignUpInput,
	TelegramSignUpInput,
} from 'src/common/axios-client'
import { useI18n } from 'vue-i18n'

export type RegisterType = 'email' | 'google' | 'telegram'

type Props = {
	type: RegisterType
}


export default /*@__PURE__*/_defineComponent({
  __name: 'register',
  props: {
    type: {}
  },
  emits: ["dialogClose"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n()

const authStore = useAuthStore()
const login = ref('')

const emit = __emit

const extraData = computed(() => {
	switch (props.type) {
		case 'email':
			return { login: login.value }
		case 'google':
			return { token: authStore.googleToken! }
		case 'telegram':
			return authStore.telegramData!
		default:
			return {}
	}
})

const props = __props
const buttonLabel = computed(() => {
	switch (props.type) {
		case 'email':
			return `${t('login.registerWith')} Email`
		case 'google':
			return `${t('login.registerWith')} Google`
		case 'telegram':
			return `${t('login.registerWith')} Telegram`
		default:
			return 'Регистрация'
	}
})

function closeDialog() {
	emit('dialogClose', true)
}

async function signUp(data: any) {
	switch (props.type) {
		case 'email':
			return emailSignUp({ ...data, login: login.value }).then(closeDialog)
		case 'google':
			return googleSignUp(data).then(closeDialog)
		case 'telegram':
			return telegramSignUp(data).then(closeDialog)
	}
}

async function emailSignUp(data: EmailSignUpInput) {
	return authStore.registerByEmail(data)
}

async function googleSignUp(data: GoogleSignUpInput) {
	return authStore.registerByGoogle(data)
}

async function telegramSignUp(data: TelegramSignUpInput) {
	return authStore.registerByTelegram(data)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(registerForm, {
    submit: signUp,
    "extra-data": extraData.value,
    "button-label": buttonLabel.value
  }, {
    default: _withCtx(() => [
      (props.type === 'email')
        ? (_openBlock(), _createBlock(emailInput, {
            key: 0,
            modelValue: login.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((login).value = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["extra-data", "button-label"]))
}
}

})