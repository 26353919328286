import { Dark } from 'quasar'
import { defineStore } from 'pinia'
import { Composer } from 'vue-i18n'
import { DEFAULT_CITY } from 'src/constants'
import {
	I18nTranslation,
	ProfileLanguageEnum,
	TinyCity,
	TinyDistrict,
	TinyMetroStation,
} from 'src/common/axios-client'
import { computed, ref, watch } from 'vue'
import { API } from 'src/boot/axios'

export interface ITab {
	title: string | any
	route: string
	parent?: string
}

export const useUiStore = defineStore(
	'ui',
	() => {
		const $i18n = ref<any>({})
		const locale = ref<ProfileLanguageEnum>(ProfileLanguageEnum.Ru)
		const darkMode = ref(false)
		const personSidebarOpened = ref(true)
		const mainSidebarOpened = ref(false)
		const isCookiesAccepted = ref(false)
		const city = ref<TinyCity & { i18: I18nTranslation }>(DEFAULT_CITY)
		const district = ref<(TinyDistrict & { i18: I18nTranslation }) | null>(null)
		const metro = ref<(TinyMetroStation & { i18: I18nTranslation }) | null>(
			null,
		)

		const i18n = computed<Composer>(() => $i18n.value.global)

		function setDarkMode(setDark: boolean) {
			Dark.set(setDark)
			darkMode.value = Dark.isActive
		}

		function toggleDarkMode() {
			Dark.toggle()
			darkMode.value = Dark.isActive
		}

		watch([locale], () => {
			updateI18()
		})

		function setCity(_city: TinyCity) {
			return API.i18n.i18nControllerGet('city', _city.id).then((r) => {
				city.value = {
					..._city,
					i18: r.data,
				}
			})
		}

		function setDistrict(_district: TinyDistrict | null) {
			if (_district === null) {
				district.value = null
				return
			}

			return API.i18n.i18nControllerGet('district', _district.id).then((r) => {
				district.value = {
					..._district,
					i18: r.data,
				}
			})
		}

		function setMetroStation(_metro: TinyMetroStation | null) {
			if (_metro === null) {
				metro.value = null
				return
			}

			return API.i18n.i18nControllerGet('metroStation', _metro.id).then((r) => {
				metro.value = {
					..._metro,
					i18: r.data,
				}
			})
		}

		async function updateI18() {
			await API.i18n.i18nControllerGet('city', city.value.id).then((r) => {
				city.value.i18 = r.data
			})

			if (district.value)
				await API.i18n
					.i18nControllerGet('district', district.value.id)
					.then((r) => {
						// ? why ts warn about this `if` ???
						if (district.value) district.value.i18 = r.data
					})

			if (metro.value)
				await API.i18n
					.i18nControllerGet('metroStation', metro.value.id)
					.then((r) => {
						// ? why ts warn about this `if` ???
						if (metro.value) metro.value.i18 = r.data
					})
		}

		return {
			$i18n,
			i18n,
			metro,
			city,
			district,
			mainSidebarOpened,
			personSidebarOpened,
			locale,
			isCookiesAccepted,
			darkMode,
			setDarkMode,
			toggleDarkMode,
			setCity,
			setDistrict,
			setMetroStation,
		}
	},
	{ persist: true },
)
