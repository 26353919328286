import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-width flex-center" }
const _hoisted_2 = { class: "full-width flex-center" }

import { useAuthStore } from 'stores/auth.store'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LoginByGoogle from './login-by-google.vue'
import LoginByTelegram from './login-by-telegram.vue'
import passwordInput from 'src/components/common/tools/password-input.vue'
import emailInput from 'src/components/common/tools/email-input.vue'
import { EmailSignInInput } from 'src/common/axios-client'
import { z } from 'zod'
import { API } from 'src/boot/axios'
import { showSuccess } from 'src/functions/toast'
import { RegisterType } from './register.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'login-by-email',
  emits: ["tab", "dialogClose"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()
const authStore = useAuthStore()

const emit = __emit

const form = ref<EmailSignInInput>({
	login: '',
	password: '',
})

const formSchema = z.object({
	login: z.string().email(),
	password: z.string().min(1),
})

const formValid = computed(() => {
	return formSchema.safeParse(form.value).success
})
const isLoading = ref(false)

const login = () => {
	isLoading.value = true
	authStore
		.loginByEmail(form.value)
		.then(() => {
			emit('dialogClose', true)
		})
		.finally(() => {
			isLoading.value = false
		})
}

const isResetButtonVEnabled = computed(() => {
	const email = form.value.login
	return !email || z.string().email().safeParse(email).success
})

function sendResetEmail() {
	API.authApi
		.emailControllerPasswordResetSendToken(
			{
				login: form.value.login,
			},
			process.env.DOMAIN,
		)
		.then(() => {
			showSuccess(
				`Ссылка на смену пароля отправлена на электронную почту ${form.value.login}`,
			)
		})
}

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, {
    onSubmit: login,
    onKeydown: _withKeys(login, ["enter"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "text-center flex column q-gutter-sm" }, {
        default: _withCtx(() => [
          _createVNode(emailInput, {
            modelValue: form.value.login,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.login) = $event)),
            validation: false
          }, null, 8, ["modelValue"]),
          _createVNode(passwordInput, {
            modelValue: form.value.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.password) = $event)),
            validation: false
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_expansion_item, {
        dense: "",
        "dense-toggle": "",
        icon: "help",
        label: _unref(t)('login.forgotPassword')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { style: {"max-width":"300px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('login.forgotPasswordText')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    disable: !isResetButtonVEnabled.value,
                    color: "primary",
                    label: _unref(t)('login.forgotPassword'),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (sendResetEmail()))
                  }, null, 8, ["disable", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_q_card_actions, {
        align: "evenly",
        class: "q-mx-sm q-my-none flex column justify-evenly full-width gap-5"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            class: "full-width",
            label: _unref(t)('login.loginButton'),
            loading: isLoading.value,
            color: "primary",
            dense: "",
            onClick: login,
            disable: !formValid.value,
            style: {"flex-grow":"1","height":"32px"}
          }, null, 8, ["label", "loading", "disable"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(LoginByGoogle, {
              onError: _cache[3] || (_cache[3] = ($event: any) => (emit('tab', 'google')))
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(LoginByTelegram, {
              onError: _cache[4] || (_cache[4] = ($event: any) => (emit('tab', 'telegram')))
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})