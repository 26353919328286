import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = {
  class: "text-grey",
  style: {"font-size":"10px","line-height":"10px"}
}
const _hoisted_3 = { class: "menu" }
const _hoisted_4 = {
  class: "row no-wrap q-pa-xs",
  style: {"overflow":"hidden"}
}
const _hoisted_5 = { class: "column dropdown-column" }
const _hoisted_6 = { class: "column items-center dropdown-column" }

import { storeToRefs } from 'pinia'
import { City, District } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'
import { useMenuStore } from 'src/stores/menu.store'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { useUiStore } from 'src/stores/ui.store'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'city-selector',
  setup(__props) {

const uiStore = useUiStore()

const { close, isOpen, toggle } = useMenuStore()

const { city: uiCity, district: uiDistrict } = storeToRefs(uiStore)

const prefetchStore = usePreFetchStore()
const { t } = useI18n()

const { menuCities } = storeToRefs(prefetchStore)

const activeCity = ref<City>(menuCities.value[0] ?? null)
const allCitiesMode = ref(false)

async function selectCity(city: City) {
	allCitiesMode.value = false
	activeCity.value = city
}

function selectDistrict(district: District | null) {
	close('city')
	uiStore.setCity(activeCity.value)
	uiStore.setMetroStation(null)
	uiStore.setDistrict(district)
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      rounded: "",
      style: {"max-height":"460px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggle)('city'))),
      class: _normalizeClass([{ selected: _unref(isOpen)('city') }, "city-btn"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "near_me",
          class: "q-mr-xs"
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_unref(uiCity).i18.text), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(uiDistrict)?.i18.text), 1)
        ])
      ]),
      _: 1
    }, 8, ["class"]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city, cityIdx) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: city.id
                    }, [
                      (cityIdx < 10)
                        ? (_openBlock(), _createBlock(_component_q_item, {
                            key: 0,
                            clickable: "",
                            class: _normalizeClass(["city-selector-item", {
									'selected-city': city.id === activeCity.value.id && !allCitiesMode.value,
								}]),
                            onClick: ($event: any) => (selectCity(city))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(city.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["class", "onClick"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  _createVNode(_component_q_item, {
                    clickable: "",
                    class: _normalizeClass(["city-selector-item", { 'selected-city': allCitiesMode.value }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (allCitiesMode.value = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('citySelector.allCities')), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_q_separator, {
          vertical: "",
          inset: "",
          class: "q-mx-xs"
        }),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city) => {
                return _withDirectives((_openBlock(), _createBlock(_component_q_list, {
                  key: city.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          class: "city-selector-item",
                          onClick: _cache[2] || (_cache[2] = 
									() => {
										_unref(uiStore).setCity(activeCity.value)
										_unref(uiStore).setDistrict(null)
										_unref(uiStore).setMetroStation(null)
										_unref(close)('city')
									}
								)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('citySelector.allDistricts')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(city.districts, (district) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        key: district.id,
                        to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}/${_unref(Slugs).DISTRICT}/${district.slug}`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item, {
                            clickable: "",
                            class: "city-selector-item",
                            onClick: ($event: any) => (selectDistrict(district))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(district.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)), [
                  [_vShow, !allCitiesMode.value && city.id === activeCity.value.id]
                ])
              }), 128)),
              _withDirectives(_createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: city.id,
                      to: `/${_unref(Slugs).CITY}/${city.slug}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          class: "city-selector-item",
                          onClick: 
									() => {
										selectCity(city)
										selectDistrict(null)
										_unref(close)('city')
									}
								
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(city.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]),
                _: 1
              }, 512), [
                [_vShow, allCitiesMode.value]
              ])
            ]),
            _: 1
          })
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)('city')]
    ])
  ], 64))
}
}

})