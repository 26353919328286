import { defineStore } from 'pinia'
import { ref } from 'vue'

type Menu = 'metro' | 'city'

export const useMenuStore = defineStore('menuStore', () => {
	const menuState = ref<Map<Menu, boolean>>(
		new Map([
			['city', false],
			['metro', false],
		]),
	)

	function open(name: Menu) {
		// выключаем все
		menuState.value.forEach((v, key, map) => {
			if (key !== name) map.set(key, false)
		})
		// включаем
		menuState.value.set(name, true)
	}

	function close(name: Menu) {
		menuState.value.set(name, false)
	}

	function isOpen(name: Menu) {
		return menuState.value.get(name)
	}

	function toggle(name: Menu) {
		if (isOpen(name)) {
			close(name)
		} else {
			open(name)
		}
	}

	return { open, close, isOpen, toggle }
})
