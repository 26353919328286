import { RouteRecordRaw } from 'vue-router'

import mainLayout from 'layouts/main-layout.vue'
import mainPage from 'pages/public/main-page.vue'

export default [
	{
		path: '/',
		component: mainLayout,
		children: [
			{
				path: '',
				name: 'main',
				component: mainPage,
			},
			{
				path: '/:id(\\d+)',
				name: 'person',
				component: () => import('pages/public/person-page.vue'),
			},
			{
				path: '/salons/:id(\\d+)',
				name: 'salon',
				component: () => import('pages/public/salon-page.vue'),
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('pages/public/about.vue'),
			},
			{
				path: '/salons/:slug+',
				name: 'salons',
				component: () => import('pages/public/salons-page.vue'),
				props: true,
			},
			{
				path: '/:slug+',
				name: 'slugs',
				component: () => import('pages/public/search-page.vue'),
				props: true,
			},
			{
				path: 'favorites',
				children: [
					{
						path: '',
						component: () => import('pages/common/favorite/index.vue'),
					},
				],
			},
		],
	},
	{
		path: '/healthcheck',
		component: () => import('pages/common/health/index.vue'),
	},
] satisfies RouteRecordRaw[]
