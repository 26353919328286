import script from "./login-by-email.vue?vue&type=script&setup=true&lang=ts"
export * from "./login-by-email.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../node_modules/.pnpm/@quasar+app-webpack@3.13.4_eslint@8.57.0_pinia@2.2.2_typescript@4.9.4_vue@3.5.3_typescript@4._kdplrr3ceeinsy2ecobt4vztby/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QCardSection,QExpansionItem,QCard,QBtn,QCardActions});
