import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useAnalytics } from 'src/composables/analytics'
import { formatPhone, normalizePhone } from 'src/functions/format'

type Props = {
	personId: number
	phone: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'phone-button',
  props: {
    personId: {},
    phone: {}
  },
  setup(__props: any) {

const props = __props

const { personContactClick } = useAnalytics()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: "button button-phone col-12",
    flat: "",
    icon: "call",
    href: `tel:${_unref(normalizePhone)(props.phone)}`,
    label: _unref(formatPhone)(props.phone),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(personContactClick)(props.personId, 'phone')))
  }, null, 8, ["href", "label"]))
}
}

})