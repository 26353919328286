import { RouteRecordRaw } from 'vue-router'
import routesDefault from './default.routes'
import routesPersonal from './personal.routes'
import routesAuth from './auth.routes'

export const routes: RouteRecordRaw[] = [
	...routesAuth,
	...routesPersonal,
	...routesDefault,
	{
		path: '/error/403',
		component: () =>
			import('pages/common/errors/error-permission-denied-page.vue'),
		props: { message: true },
		name: '403',
	},
	{
		path: '/error/404',
		component: () => import('pages/common/errors/error-not-found-page.vue'),
		props: { message: true },
		name: '404',
	},

	// Always leave this as last one,
	// but you can also remove it
	{
		path: '/:catchAll(.*)*',
		component: () => import('pages/common/errors/error-not-found-page.vue'),
	},
]
