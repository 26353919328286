import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { API } from 'src/boot/axios'
import { TelegramSignInInput } from 'src/common/axios-client'
import telegramAuthBtn from 'src/components/common/telegram-auth-btn.vue'
import { useAuthStore } from 'src/stores/auth.store'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'login-by-telegram',
  emits: ['error'],
  setup(__props, { emit: __emit }) {

const router = useRouter()
const authStore = useAuthStore()
const emit = __emit

function login(data: TelegramSignInInput) {
	return API.authApi
		.telegramControllerSignIn(data)
		.then(async (r) => {
			await authStore.setTokens(r.data)
			router.push('/')
		})
		.catch(() => {
			emit('error')
		})
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(telegramAuthBtn, { "api-fn": login }))
}
}

})