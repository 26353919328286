import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { QBtnProps } from 'quasar'
import { useFavoriteStore } from 'src/stores/favorite.store'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = Omit<QBtnProps, 'icon' | 'label'> & {
	personId: number
	btnStyle: 'icon' | 'button'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'favorite-btn',
  props: {
    size: {},
    type: {},
    to: {},
    replace: { type: Boolean },
    href: {},
    target: {},
    iconRight: {},
    outline: { type: Boolean },
    flat: { type: Boolean },
    unelevated: { type: Boolean },
    rounded: { type: Boolean },
    push: { type: Boolean },
    square: { type: Boolean },
    glossy: { type: Boolean },
    fab: { type: Boolean },
    fabMini: { type: Boolean },
    padding: {},
    color: {},
    textColor: {},
    noCaps: { type: Boolean },
    noWrap: { type: Boolean },
    dense: { type: Boolean },
    ripple: { type: Boolean },
    tabindex: {},
    align: {},
    stack: { type: Boolean },
    stretch: { type: Boolean },
    loading: { type: [Boolean, null] },
    disable: { type: Boolean },
    round: { type: Boolean },
    percentage: {},
    darkPercentage: { type: Boolean },
    onClick: { type: Function },
    personId: {},
    btnStyle: {}
  },
  setup(__props: any) {

const favoriteStore = useFavoriteStore()
const { t } = useI18n()

const props = __props

const isFavorite = computed(() => favoriteStore.isFavorite(props.personId))
const isLoading = ref(false)

const iconName = computed(() =>
	isFavorite.value ? 'favorite' : 'favorite_outline',
)

const label = computed(() => {
	if (props.btnStyle === 'icon') return undefined
	if (isFavorite.value) return t('labels.rmFromFavorite')
	else return t('labels.addToFavorite')
})

function toggle() {
	isLoading.value = true
	if (isFavorite.value) {
		// remove
		favoriteStore.rm(props.personId).finally(() => {
			isLoading.value = false
		})
	} else {
		favoriteStore.add(props.personId).finally(() => {
			isLoading.value = false
		})
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.btnStyle === 'button')
      ? (_openBlock(), _createBlock(_component_q_btn, _mergeProps({
          key: 0,
          flat: "",
          icon: iconName.value,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggle()), ["prevent"])),
          class: "fav",
          label: label.value
        }, _ctx.$attrs, { loading: isLoading.value }), null, 16, ["icon", "label", "loading"]))
      : _createCommentVNode("", true),
    (props.btnStyle === 'icon')
      ? (_openBlock(), _createBlock(_component_q_icon, _mergeProps({
          key: 1,
          size: "sm",
          name: iconName.value,
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (toggle()), ["prevent"])),
          class: ["fav pulse", { loading: isLoading.value }]
        }, _ctx.$attrs), null, 16, ["name", "class"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})