import { defineStore } from 'pinia'

import { API } from 'boot/axios'
import { IMedia, IMediaWithUrls } from '@interfaces/media.interface'
import { ImageControllerResizeSizeEnum } from 'src/common/axios-client'

export const useMediaStore = defineStore('mediaStore', {
	actions: {
		async getImageUrl(
			imageId: number,
			size: ImageControllerResizeSizeEnum,
		): Promise<string> {
			return API.imageApi
				.imageControllerResize(size, imageId)
				.then((r) => r.data)
		},
		async extendMediaWithUrl(
			media: IMedia,
			size: ImageControllerResizeSizeEnum,
		): Promise<IMediaWithUrls> {
			if (media.kind === 'image') {
				return this.getImageUrl(media.id, size).then((url) => ({
					...media,
					url,
				}))
			}
			const previewUrl = media.previewId
				? await this.getImageUrl(media.previewId, size)
				: null
			return { ...media, previewUrl }
		},
	},
})
