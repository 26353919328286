import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex row no-wrap gap-10",
  style: {"margin-top":"9px"}
}

import { Category } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'categories-menu',
  setup(__props) {

const prefetchStore = usePreFetchStore()

const { categories } = storeToRefs(prefetchStore)

function categoryRoute(category: Category): string {
	return `/${Slugs.CATEGORY}/${category.slug}`
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!

  return (_openBlock(), _createBlock(_component_q_scroll_area, {
    style: {"height":"42px"},
    class: "full-width align-center"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex",
            key: category.id
          }, [
            _createVNode(_component_router_link, {
              to: categoryRoute(category),
              class: "category-item",
              exact: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(category.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})