import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from 'assets/images/18+.svg'


const _hoisted_1 = { class: "full-width" }
const _hoisted_2 = { class: "flex row justify-center" }
const _hoisted_3 = { class: "q-mx-sm" }
const _hoisted_4 = { class: "flex row footer justify-between q-px-md" }
const _hoisted_5 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_6 = { class: "app-title" }
const _hoisted_7 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_8 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_9 = { class: "row justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_10 = { class: "absolute-bottom-right q-ma-md" }

import { Screen } from 'quasar'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'app-footer',
  setup(__props) {

const { t } = useI18n()

const expanded = ref(false)

const currentYear = new Date().getFullYear()

function closeFooter() {
	if (Screen.xs) expanded.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_slide_transition = _resolveComponent("q-slide-transition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_q_btn, {
        flat: "",
        icon: expanded.value ? 'keyboard_arrow_down' : 'keyboard_arrow_up',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (expanded.value = false)),
        class: "full-width"
      }, null, 8, ["icon"]), [
        [_vShow, expanded.value]
      ]),
      _withDirectives(_createVNode(_component_q_btn, {
        class: "pointer q-pa-xs text-center full-width",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (expanded.value = true))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("strong", null, _toDisplayString(_unref(t)('appTitle')), 1)
            ]),
            _createElementVNode("div", null, _toDisplayString(_unref(t)('footer.collapsedBtnText')), 1)
          ])
        ]),
        _: 1
      }, 512), [
        [_vShow, !expanded.value]
      ])
    ]),
    _createVNode(_component_q_slide_transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)('appTitle')), 1),
            _createElementVNode("p", null, [
              _createTextVNode(" © 2021–" + _toDisplayString(_unref(currentYear)) + ", ", 1),
              _createElementVNode("strong", null, _toDisplayString(_unref(t)('appTitle')), 1),
              _createTextVNode(" — " + _toDisplayString(_unref(t)('footer.description')) + ". ", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_q_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (closeFooter())),
                  to: "/"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.main')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (closeFooter())),
                  to: "/about"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.about')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (closeFooter())),
                  to: "/advert"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.advert')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (closeFooter())),
                  to: "/founding"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.founding')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (closeFooter())),
                  to: "/health-plus"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.health')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (closeFooter())),
                  to: "/metro"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.metro')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (closeFooter())),
                  to: "/districts"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.districts')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (closeFooter())),
                  to: "/roads"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.roads')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (closeFooter())),
                  to: "/cities"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.cities')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_q_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (closeFooter())),
                  to: "/eula-customer"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.eulaCustomer')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (closeFooter())),
                  to: "/eula-user"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.eulaUser')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (closeFooter())),
                  to: "/privacy-rules"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.privacyRules')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, {
                  "manual-focus": "",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (closeFooter())),
                  to: "/contacts"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('footer.links.contacts')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_q_list, { class: "row" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, { "manual-focus": "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_btn, {
                        "fab-mini": "",
                        flat: "",
                        rounded: "",
                        icon: "fa-brands fa-telegram"
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item, { "manual-focus": "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_btn, {
                        "fab-mini": "",
                        flat: "",
                        rounded: "",
                        icon: "fa-brands fa-x-twitter"
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_q_img, {
              class: "q-ml-sm",
              loading: "lazy",
              width: "38px",
              height: "38px",
              src: _imports_0
            })
          ])
        ], 512), [
          [_vShow, expanded.value]
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})