import { defineStore, storeToRefs } from 'pinia'
import { API } from 'src/boot/axios'
import { Banner } from 'src/common/axios-client'
import { ref, watch } from 'vue'
import { useUiStore } from './ui.store'

export const useBannerStore = defineStore('bannerStore', () => {
	const uiStore = useUiStore()

	const { city } = storeToRefs(uiStore)
	const banners = ref<Banner[]>([])

	async function updateBanners() {
		await API.bannersApi.bannerControllerGetBanners(city.value.id).then((r) => {
			banners.value = r.data
		})
	}

	watch(city, () => {
		updateBanners()
	})

	return {
		updateBanners,
		banners,
	}
})
