import { useRouter } from 'vue-router'
import { jwtDecode } from 'jwt-decode'
import { useAuthStore } from '../stores/auth.store'
import { Tokens } from 'src/common/axios-client'
import { getSharedSecret } from '@interfaces/crypto'

const IMPERSONATE_QUERY_NAME = 'iu'
const IMPERSONATE_SIGN_QUERY_NAME = 'ius'

export function useImpersonate() {
	if (!process.env.CLIENT) return

	const router = useRouter()
	const authStore = useAuthStore()
	const route = router.currentRoute

	const token = route.value.query[IMPERSONATE_QUERY_NAME]
	const signature = route.value.query[IMPERSONATE_SIGN_QUERY_NAME]
	if (
		!token ||
		typeof token !== 'string' ||
		!signature ||
		typeof signature !== 'string'
	) {
		return
	}

	try {
		jwtDecode(token)
		verifySignature(token, signature).then((isValid) => {
			if (!isValid) return

			const tokens = {
				accessToken: token,
				/**
				 * after accessToken expires refresh **will fail**
				 */
				refreshToken: undefined as unknown as string,
			} satisfies Tokens

			authStore.setTokens(tokens).then(() => {
				router.push('/')
			})
		})
	} catch {
	} finally {
		router.push('/')
	}
}

async function importKey() {
	const secret = getSharedSecret()
	return crypto.subtle.importKey(
		'raw',
		new TextEncoder().encode(secret),
		{ name: 'HMAC', hash: 'SHA-256' },
		false,
		['verify'],
	)
}

async function verifySignature(
	message: string,
	signature: string,
): Promise<boolean> {
	const key = await importKey()

	// Convert hex to Uint8Array
	const sigBuf = Uint8Array.from(
		signature.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)),
	)

	return crypto.subtle.verify(
		'HMAC',
		key,
		sigBuf,
		new TextEncoder().encode(message),
	)
}
