import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex row main col-8 col-lg-8 col-md-12 col-sm-12 col-xs-12" }
const _hoisted_2 = { class: "flex row" }
const _hoisted_3 = { class: "app-title q-ma-sm" }
const _hoisted_4 = {
  key: 0,
  class: "flex row q-ml-auto justify-center self-center fade-in"
}
const _hoisted_5 = { class: "flex row justify-center fade-in" }
const _hoisted_6 = { class: "row q-ml-auto gap-5" }
const _hoisted_7 = { class: "flex q-my-xs q-ml-auto full-width" }
const _hoisted_8 = {
  class: "flex self-center no-wrap",
  style: {"flex-grow":"1","height":"42px"}
}
const _hoisted_9 = { class: "flex q-ml-auto justify-center fade-in" }
const _hoisted_10 = {
  class: "main col-8 col-lg-8 col-md-12 col-sm-12 col-xs-12",
  style: {"margin-bottom":"20px"}
}
const _hoisted_11 = { class: "q-mt-md" }
const _hoisted_12 = { class: "flex row full-width features-block justify-between justify-center gap-20" }

import LanguageSwitch from 'components/common/tools/language-switch.vue'
import DarkModeToggle from 'components/common/tools/dark-mode-toggle.vue'
import NotificationBell from 'components/common/tools/notification-bell.vue'
import mainSidebar from 'src/components/public/main-sidebar.vue'
import appFooter from 'src/components/public/app-footer.vue'
import CitySelector from 'src/components/common/tools/city-selector.vue'
import metroSelector from 'src/components/common/tools/metro-selector.vue'
import bannerList from 'src/components/public/banner/banner-list.vue'
import favoriteCounter from 'src/components/common/favorite-counter.vue'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useAuthStore } from 'stores/auth.store'
import { useUiStore } from 'stores/ui.store'
import CategoriesMenu from 'components/public/categories-menu.vue'
import { Screen } from 'quasar'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { useRouter } from 'vue-router'
import { useBannerStore } from 'src/stores/banner.store'
import { storeToRefs } from 'pinia'
import { useAuth } from 'src/composables/auth'


export default /*@__PURE__*/_defineComponent({
  ...{
	async preFetch({ currentRoute }) {
		const prefetchStore = usePreFetchStore()
		const bannerStore = useBannerStore()
		const isMainPage = currentRoute.path === '/'

		const promises = [prefetchStore.update()]

		if (isMainPage) promises.push(bannerStore.updateBanners())
		return Promise.all(promises)
	},
},
  __name: 'main-layout',
  setup(__props) {

const { t } = useI18n()

const authStore = useAuthStore()
const uiStore = useUiStore()
const search = ref('')

const router = useRouter()
const isMainPage = computed(() => router.currentRoute.value.path === '/')

const drawerWidth = computed(() => {
	const clientWidth = Screen.width
	const defaultWidth = 540
	return Math.min(clientWidth, defaultWidth)
})

const prefetchStore = usePreFetchStore()

const { isCityHasMetro } = storeToRefs(prefetchStore)



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_footer = _resolveComponent("q-footer")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, { class: "row" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_btn, {
                outline: "",
                icon: "menu",
                class: "sidebar-btn sidebar-btn-40x40 self-center q-ma-sm",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(uiStore).mainSidebarOpened = !_unref(uiStore).mainSidebarOpened))
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('appTitle')), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.$q.screen.width !== 0 && _ctx.$q.screen.lt.md)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_q_btn, {
                    icon: "search",
                    rounded: "",
                    class: "sidebar-btn-40x40",
                    direction: "left"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_menu, { style: {"width":"75%"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item, { style: {"padding":"0"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_input, {
                                    class: "q-px-sm self-center full-width",
                                    dense: "",
                                    modelValue: search.value,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                                    clearable: "",
                                    type: "search",
                                    "bg-color": "dark",
                                    placeholder: _unref(t)('Search')
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.$q.screen.gt.sm)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_input, {
                      class: "q-ma-xs self-center",
                      dense: "",
                      modelValue: search.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((search).value = $event)),
                      clearable: "",
                      outlined: "",
                      type: "search",
                      "bg-color": "dark",
                      placeholder: _unref(t)('Search')
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          flat: "",
                          round: "",
                          icon: "search"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"]),
                    _createVNode(_component_q_btn, {
                      dense: "",
                      icon: "ion-globe",
                      class: "sidebar-btn sidebar-btn-40 q-ma-sm self-center",
                      label: _unref(t)('mainSidebar.map')
                    }, null, 8, ["label"])
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex row justify-center fade-in" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(favoriteCounter, { class: "sidebar-btn-40 self-center" }),
                    (_unref(authStore).user)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(NotificationBell, { class: "sidebar-btn-40 self-center" }),
                          _createVNode(_component_q_btn, {
                            round: "",
                            flat: "",
                            "fab-mini": "",
                            class: "sidebar-btn-40 login-button self-center",
                            icon: "person",
                            title: _unref(t)('mainSidebar.viewProfile'),
                            to: "/personal/profile"
                          }, null, 8, ["title"])
                        ], 64))
                      : (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          "fab-mini": "",
                          round: "",
                          flat: "",
                          class: "sidebar-btn-40 login-button self-center",
                          icon: "login",
                          title: _unref(t)('mainSidebar.signin'),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(useAuth)().login()))
                        }, null, 8, ["title"])),
                    _createVNode(_component_q_btn, {
                      rounded: "",
                      flat: "",
                      class: "sidebar-btn-40 add-card self-center",
                      icon: "check",
                      label: _unref(t)('mainSidebar.addCard'),
                      title: _unref(t)('mainSidebar.addCard'),
                      to: "/personal/cards"
                    }, null, 8, ["label", "title"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(CategoriesMenu)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      (_unref(isCityHasMetro))
                        ? (_openBlock(), _createBlock(metroSelector, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createVNode(CitySelector),
                      _createVNode(LanguageSwitch),
                      _createVNode(DarkModeToggle)
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _withDirectives((_openBlock(), _createBlock(_component_q_drawer, {
        modelValue: _unref(uiStore).mainSidebarOpened,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(uiStore).mainSidebarOpened) = $event)),
        dark: _unref(uiStore).darkMode,
        overlay: "",
        bordered: "",
        width: drawerWidth.value
      }, {
        default: _withCtx(() => [
          _createVNode(mainSidebar)
        ]),
        _: 1
      }, 8, ["modelValue", "dark", "width"])), [
        [_directive_close_popup]
      ]),
      _createVNode(_component_q_page_container, { class: "row" }, {
        default: _withCtx(() => [
          (isMainPage.value)
            ? (_openBlock(), _createBlock(bannerList, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_router_view),
            _createElementVNode("h2", _hoisted_11, _toDisplayString(_unref(t)('mainLayout.bottom.title')) + ":", 1),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ " + _toDisplayString(_unref(t)('mainLayout.bottom.block1.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(t)('mainLayout.bottom.block1.text')), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ " + _toDisplayString(_unref(t)('mainLayout.bottom.block2.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(t)('mainLayout.bottom.block2.text')), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ " + _toDisplayString(_unref(t)('mainLayout.bottom.block3.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(t)('mainLayout.bottom.block3.text')), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ " + _toDisplayString(_unref(t)('mainLayout.bottom.block4.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(t)('mainLayout.bottom.block4.text')), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ " + _toDisplayString(_unref(t)('mainLayout.bottom.block5.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(t)('mainLayout.bottom.block5.text')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_footer, { bordered: "" }, {
        default: _withCtx(() => [
          _createVNode(appFooter)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})