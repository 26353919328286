import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, renderList as _renderList, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex column q-ma-md gap-10" }
const _hoisted_2 = { class: "flex row justify-center" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex row justify-center gap-10" }
const _hoisted_7 = { style: {"flex-grow":"1"} }
const _hoisted_8 = {
  class: "flex self-center justify-center gap-10",
  style: {"flex-grow":"0"}
}
const _hoisted_9 = { class: "flex row justify-start" }
const _hoisted_10 = { class: "flex full-width no-wrap" }
const _hoisted_11 = { class: "flex q-ml-auto justify-center self-center" }
const _hoisted_12 = { class: "flex row justify-center" }

import LanguageSwitch from 'components/common/tools/language-switch.vue'
import DarkModeToggle from 'components/common/tools/dark-mode-toggle.vue'
import NotificationBell from 'components/common/tools/notification-bell.vue'
import CitySelector from 'src/components/common/tools/city-selector.vue'
import metroSelector from '../common/tools/metro-selector.vue'
import favoriteCounter from '../common/favorite-counter.vue'
import { useUiStore } from 'stores/ui.store'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { computed } from 'vue'
import { FeatureValue } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'
import { storeToRefs } from 'pinia'
import { useAuthStore } from 'src/stores/auth.store'
import { useI18n } from 'vue-i18n'
import { useAuth } from 'src/composables/auth'


export default /*@__PURE__*/_defineComponent({
  __name: 'main-sidebar',
  setup(__props) {

const uiStore = useUiStore()
const authStore = useAuthStore()
const prefetchStore = usePreFetchStore()
const { t } = useI18n()

const { features, isCityHasMetro } = storeToRefs(prefetchStore)

const chunkedFeatures = computed(() => {
	const middle = Math.trunc(features.value.length / 2)
	const left = features.value.slice(0, middle)
	const right = features.value.slice(middle)

	return [left, right].map((e) => ({
		id: Math.random(),
		list: e,
	}))
})

function getRoutePath(item: FeatureValue): string {
	return `/${Slugs.FEATURE}/${item.slug}`
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.screen.xs || _ctx.$q.screen.sm)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  flat: "",
                  class: "add-card",
                  icon: "check",
                  rounded: "",
                  label: _unref(t)('mainSidebar.addCard'),
                  to: "/personal/cards"
                }, null, 8, ["label"])
              ]),
              (_unref(authStore).user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      class: "login-button q-pa-xs",
                      icon: "person",
                      rounded: "",
                      label: _unref(t)('mainSidebar.viewProfile'),
                      to: "/personal/profile"
                    }, null, 8, ["label"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_q_btn, {
                      rounded: "",
                      flat: "",
                      class: "login-button",
                      icon: "login",
                      label: _unref(t)('mainSidebar.signin'),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(useAuth)().login()))
                    }, null, 8, ["label"])
                  ])),
              _createVNode(favoriteCounter, { class: "sidebar-btn-40 self-center" })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_q_btn, {
                icon: "ion-globe",
                class: "q-ma-sm self-center full-width",
                label: _unref(t)('mainSidebar.map')
              }, null, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_unref(isCityHasMetro))
                ? (_openBlock(), _createBlock(metroSelector, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(CitySelector),
              _createElementVNode("div", null, [
                (_unref(authStore).user)
                  ? (_openBlock(), _createBlock(NotificationBell, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(LanguageSwitch),
                _createVNode(DarkModeToggle)
              ])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("h1", null, _toDisplayString(_unref(t)('mainSidebar.serviceList')), 1),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_q_btn, {
            icon: "close",
            class: "q-pa-xs",
            flat: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(uiStore).mainSidebarOpened = false))
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chunkedFeatures.value, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex column q-gutter-md",
          key: column.id,
          style: {"flex-grow":"1","width":"50%"}
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.list, (prop) => {
            return (_openBlock(), _createElementBlock("div", {
              key: prop.id
            }, [
              _createElementVNode("h4", null, _toDisplayString(prop.name), 1),
              _createVNode(_component_q_list, { dense: "" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prop.values, (item) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                      key: item.id,
                      dense: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: getRoutePath(item),
                          class: "sidebar-link"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
}

})