export default {
	locale: 'English',
	failed: 'Action failed',
	success: 'Action was successful',
	appTitle: 'ashoo',
	common: { back: 'Go back' },
	usertype: { customer: 'Customer', user: 'Advertiser' },
	language: { english: 'English', russian: 'Russian' },
	actions: { edit: 'Edit', cancel: 'Cancel', save: 'Save' },
	labels: {
		city: 'City',
		district: 'District',
		phone: 'Phone number',
		salon: 'Salon',
		metro: 'Metro',
		country: 'Country',
		balance: 'Balance',
		noData: 'No data found',
		addToFavorite: 'Add to favorites',
		rmFromFavorite: 'Remove from favorites',
		saved: 'Saved',
	},
	login: {
		tabTitle: 'Sign in',
		tabRegistration: 'Sign up',
		title: 'Welcome!',
		byEmail: 'Login',
		byPhone: 'Phone',
		appInfo: 'App info',
		emailLabel: 'Email',
		nickname: 'Nick name',
		language: 'Language',
		timezone: 'Timezone',
		country: 'Country',
		passwordLabel: 'Password',
		makeCall: 'Make a verify call',
		sendSMS: 'Send code in SMS',
		codeSmsLabel: 'SMS-code',
		codeCallLabel: 'Last 4 digits',
		loginButton: 'Log in',
		registerButton: 'Register',
		close: 'Close',
		activating: 'Activating..',
		useActivateLinkInEmail: 'Activation email was sent.',
		checkEmailButton: 'Continue',
		logout: 'Logout',
		healthLink: 'healthLink',
		forgotPassword: 'Recover password',
		forgotPasswordText:
			'Enter your email above and click the "Recover Password" button',
		registerWith: 'Register with',
	},
	logout: {
		title: 'Logout',
		confirmText: 'Logout',
		cancel: 'No',
		proceed: 'Logout',
	},
	updater: {
		messagePre: 'Update is available! Please save your changes and',
		messageLink: 'Refresh',
		messagePost: 'the page',
	},
	cookies: {
		message:
			'We use cookies: technical, analytical, marketing and others. They are necessary for the optimal operation of sites and services, help us to recognize you and store your preferences.',
		acceptButton: 'Accept',
	},
	errors: {
		'nothing-here': 'Oops. Nothing here...',
		unknownError: 'Unknown error',
	},
	menu: {
		title: 'Menu',
		Replenish: 'Top ups',
		Transactions: 'Transactions',
		Card: 'Cards',
		Salon: 'Salons',
		Support: 'Support',
		Profile: 'Profile',
		Notifications: 'Notifications',
		Favorites: 'Favorites',
	},
	name: 'Name',
	yourName: 'Your name',
	phone: 'Phone number',
	cartPage: {
		receipts: 'Receipts',
		addBag: 'Add to bag',
		total: 'Total',
		goToPayment: 'Go to payment',
		addItem: 'Add item',
		payOnCash: 'Pay on cash',
		payByKaspi: 'Pay by Kaspi',
		receipt: 'Receipt',
		thereIsNoItemsToShow: 'There is no items to show',
		yourOrderNumberIs: 'Your order number is',
		showThisNumberToCashierToMakePayment:
			'Show this number to cashier to make payment',
		stopPurchase: 'Stop purchase',
		methodOfPayment: 'Method of payment',
		byCash: 'By cash',
		listOfPurchase: 'List of purchase',
	},
	profilePage: {
		pleaseEnterCorrectNumber: 'Please enter correct phone number',
		enterCorrectInformation: 'Please enter correct information',
	},
	Search: 'Name, description',
	authStore: {
		emailSignUpSuccess: 'Login email sent to {email}',
		emailSignUpFailed: 'Failed to send email, please contact support',
		signUpSuccess: 'Registration completed',
		refreshFailed: 'Failed to log in automatically',
		logout: 'You have been logged out',
	},
	personCard: {
		phoneButtonTitle: 'Show contacts',
		prices: {
			oneHour: '1 hour',
			twoHour: '2 hours',
			night: 'Night',
			hasExpress: 'Express available',
		},
	},
	personPage: {
		showContacts: 'Show phone',
		p_unavailable:
			'Attention! This profile is NOT AVAILABLE or the girl is temporarily not working. Information taken from the archive.',
		cardNumber: 'Profile number',
		descriptionMoreButton: 'More details',
		prepaymentText: 'You can transfer an advance payment of up to',
		preferables: 'Preferences',
		comments: 'Reviews',
		noComments: 'No reviews yet',
		logInAndComment: 'Log in and leave a review',
		newCommentTitle: 'Your comment',
		sendButton: 'Send',
		videoTitle: 'Video',
		tariffTitle: 'Tariff',
		prepaymentCheckBlock:
			'Payment details have been verified. If he asks for an advance, send it to a card ending in ',
		cardsCount: 'Profiles on this number',
		cardsCountButton: 'View',
		friendsTitle: 'Girlfriends',
		nearbyTitle: 'Prostitutes nearby',
		lastActivityTime: 'Last activity at',
		healthTitle: 'Health check date',
		commentSent: 'Comment sent to verification',
	},
	salonPage: {
		timeSince: 'On site since',
		info: 'Information about salon',
		showPhone: 'Show phone',
		workingTime: 'Working hours',
		prices: 'Prices',
		models: 'Girls',
	},
	salonsPage: {
		title: 'Intimate salons',
		in: 'in',
	},
	mainLayout: {
		bottom: {
			title: 'Ashoo prostitutes website',
			block1: {
				title: 'Reliable',
				text: 'Only real verified profiles and ads',
			},
			block2: {
				title: 'Convenient',
				text: 'A large selection of prostitutes for every taste and price',
			},
			block3: { title: 'Fast', text: 'There will definitely be one near you' },
			block4: {
				title: 'Reliable',
				text: "The prostitutes' numbers are listed on the website and they are waiting for your call",
			},
			block5: {
				title: 'Around the clock',
				text: 'We work on weekends and holidays 24 hours a day',
			},
		},
	},
	mainPage: {
		header: { title: 'Profiles on Ashoo', online: 'online' },
		allButton: 'View all',
		new: 'New profiles on Ashoo',
		top20: 'Top 20',
		salons: 'Salons',
		massage: 'Masseuses on Ashoo',
		p1: 'On the site with prostitutes you can find any profiles of girls by parameters (height, weight, breast size), hair color or preferences in sex. We have a large selection of Moscow prostitutes, ready to do anything for your pleasure. Each girl has detailed profiles with real photos, reviews and coordinates. There are separate categories of the site by nationality, hair color, weight, age. Here you can find an elite girl, a whore, agreeing to anal, providing a blowjob service. There are also private escorts ready to travel to any area of ​​your city.',
		p2: 'There are separate categories of the site by nationality, hair color, weight, age. Here you can find an elite girl, a whore, who agrees to anal, providing a blowjob service. There are also private escorts ready to travel to any area of ​​your city.',
		howto: {
			title: 'How to call a prostitute in Moscow',
			p1: "To provide intimate services, you can use Telegram, WhatsApp, Viber or a call to a mobile phone. The prostitutes' numbers are listed in the profiles, there are also links to personal messengers.",
			p2: "The site guarantees complete anonymity. We do not save clients' personal data and do not transfer it to third parties.",
		},
	},
	mainSidebar: {
		addCard: 'Add profile',
		viewProfile: 'Profile',
		signin: 'Sign in',
		map: 'Explore map',
		serviceList: 'List of services',
	},
	citySelector: {
		allCities: 'All cities',
		allDistricts: 'All districts',
	},
	metroSelector: {
		search: 'Search station',
		allStations: 'All stations',
	},
	intimeMap: {
		title: 'Intimacy Map',
		text: 'Convenient selection of offers near you',
		button: 'Explore map',
	},
	personProperties: { tags: 'Tags' },
	notificationPage: {
		title: 'Notifications',
		readAll: 'Read all',
		noNotifications: 'There are no notifications',
	},
	notificationBell: {
		noNotifications: 'No new notifications',
		seeAll: 'See all',
	},
	personTariff: {
		day: 'Day',
		night: 'Night',
		onUser: 'At me',
		onCustomer: 'At you',
	},
	emailInput: 'Email',
	emailProvider: {
		verified: 'Verified',
		notVerified: 'Email address not verified',
		sendAgain: 'Send email again',
		resetPassword: 'Reset password',
		notLinked: 'Email not linked',
		link: 'Link email',
	},
	authProviders: { title: 'Authorization providers', delete: 'Remove' },
	profileEdit: { title: 'Profile' },
	footer: {
		collapsedBtnText:
			'Convenient search for prostitutes, masseuses and escort models throughout Russia',
		description:
			'a project created for convenient search for prostitutes, masseuses and escort models throughout Russia',
		links: {
			main: 'Home',
			about: 'About the project',
			advert: 'Advertising on the site',
			founding: 'Ashoo Foundation',
			health: 'HEALTH+',
			metro: 'Metro',
			districts: 'Districts',
			roads: 'Highways',
			cities: 'Cities',
			eulaCustomer: 'User Agreement for clients',
			eulaUser: 'User Agreement for advertisers',
			privacyRules: 'Privacy policy',
			contacts: 'Contacts',
		},
	},
	replenish: {
		btnTitle: 'Top up',
		title: 'Top up',
		filter: {
			statusLabel: 'Top up status',
			minAmountLabel: 'Top up (from)',
			maxAmountLabel: 'Top up (to)',
			dateSelected: 'Dates selected',
			dateNotSelected: 'No dates selected',
			apply: 'Apply',
		},
		statusLabel: 'Status',
		providerLabel: 'Operator',
		amountLabel: 'Amount',
		feeLabel: 'Fee',
		usdAmountLabel: 'Amount in USD',
		dateLabel: 'Date',
		filterLabel: 'Filters',
		status: {
			approved: 'Approved',
			new: 'Sent',
			rejected: 'Rejected',
		},
		noFee: 'No fee',
	},
	replenishNew: {
		btnTitle: 'Top up',
		title: 'Top up balance',
		providerLabel: 'Payment provider',
		amountLabel: 'Amount',
		commentLabel: 'Comment',
		ruleExpansionLabel: 'Terms of payment acceptance',
		rules: {
			minAmount: 'Minimum top-up',
			requisites: 'Requisites',
			text: 'Terms',
		},
		currencyLabel: 'Top-up currency',
		feeLabel: 'Commission',
		ruleCheckboxLabel: 'I have read the terms of payment acceptance',
		submitBtnLabel: 'Submit top-up request',
		fileLabel: 'Receipt file',
		validation: {
			minAmount: 'Amount must be greater than {min} {currency}',
			fileSize: 'File size must not exceed {size} MB',
		},
		successText: 'Top-up request sent',
	},
	validation: {
		isNumber: 'Must be a number',
		gt: 'Must be greater than {value}',
	},
	favoritesPage: {
		title: 'Favorites',
	},
}
