import { defineStore } from 'pinia'
import { API } from 'src/boot/axios'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notificationStore', () => {
	const newCount = ref(0)

	function fetchNewNotifications(limit: number, offset: number) {
		return API.notificationsApi
			.notificationControllerFindNew(limit, offset)
			.then((e) => {
				newCount.value = e.data.total
				return e.data
			})
	}

	function fetchNotifications(limit: number, offset: number) {
		return API.notificationsApi
			.notificationControllerFindAll(limit, offset)
			.then((e) => e.data)
	}

	function markAsSeen(id: string) {
		return API.notificationsApi
			.notificationControllerUpdateStatus(id, {
				status: 'readed',
			})
			.then(() => {
				newCount.value -= 1
			})
	}

	function maskAllAsSeen() {
		return API.notificationsApi
			.notificationControllerMarkNewNotificationsReaded()
			.then(() => {
				newCount.value = 0
			})
	}

	function markAsDeleted(id: string) {
		return API.notificationsApi.notificationControllerUpdateStatus(id, {
			status: 'deleted',
		})
	}

	return {
		newCount,
		fetchNewNotifications,
		fetchNotifications,
		markAsSeen,
		maskAllAsSeen,
		markAsDeleted,
	}
})
