import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { PersonTag } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'

type Props = {
	tag: PersonTag
}


export default /*@__PURE__*/_defineComponent({
  __name: 'person-tag',
  props: {
    tag: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/${_unref(Slugs).TAG}/${props.tag.slug}`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_chip, {
        class: _normalizeClass(["tag", { accent: props.tag.isStarred }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.tag.name), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})