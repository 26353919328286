import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "full-width flex row gap-30 no-wrap justify-center" }

import { storeToRefs } from 'pinia'
import bannerItem from './banner-item.vue'
import { useBannerStore } from 'src/stores/banner.store'


export default /*@__PURE__*/_defineComponent({
  __name: 'banner-list',
  setup(__props) {

const bannerStore = useBannerStore()
const { banners } = storeToRefs(bannerStore)

return (_ctx: any,_cache: any) => {
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!

  return (_unref(banners).length)
    ? (_openBlock(), _createBlock(_component_q_scroll_area, {
        key: 0,
        class: "full-width scroll",
        visible: false,
        "thumb-style": { height: '0px' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(banners), (banner) => {
              return (_openBlock(), _createBlock(bannerItem, {
                key: banner.link,
                banner: banner
              }, null, 8, ["banner"]))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})