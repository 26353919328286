export default {
	locale: 'Русский',
	failed: 'Не получилось',
	success: 'Получилось!',
	appTitle: 'ashoo',
	common: { back: 'Назад' },
	usertype: { customer: 'Посетитель', user: 'Рекламодатель' },
	language: { english: 'Английский', russian: 'Русский' },
	actions: { edit: 'Редактировать', cancel: 'Отменить', save: 'Сохранить' },
	labels: {
		city: 'Город',
		district: 'Район',
		phone: 'Телефон',
		salon: 'Салон',
		metro: 'Метро',
		country: 'Страна',
		balance: 'Баланс',
		noData: 'Данные не найдены',
		addToFavorite: 'Добавить в избранное',
		rmFromFavorite: 'Убрать из избранного',
		saved: 'Сохранено',
	},
	login: {
		tabTitle: 'Вход',
		tabRegistration: 'Регистрация',
		title: 'Привет!',
		byEmail: 'Вход',
		byPhone: 'Телефон',
		appInfo: 'Информация',
		emailLabel: 'Электронная почта',
		nickname: 'Ник',
		language: 'Язык',
		timezone: 'Часовой пояс',
		country: 'Страна',
		passwordLabel: 'Пароль',
		makeCall: 'Сделать звонок',
		sendSMS: 'Отправить СМС',
		codeSmsLabel: 'Код из СМС',
		codeCallLabel: 'Последние 4 цифры номера',
		loginButton: 'Войти',
		registerButton: 'Регистрация',
		close: 'Закрыть',
		activating: 'Активация..',
		useActivateLinkInEmail: 'Отправленная электронная почта',
		checkEmailButton: 'Далее',
		logout: 'Выйти',
		healthLink: 'healthLink',
		forgotPassword: 'Восстановить пароль',
		forgotPasswordText:
			'Введите электронную почту выше и нажмите кнопку "Восстановить пароль"',
		registerWith: 'Регистрация с',
	},
	logout: {
		title: 'Выйти',
		confirmText: 'Выйти из аккаунта',
		cancel: 'Нет, не выходить',
		proceed: 'Выйти',
	},
	updater: {
		messagePre: 'Вышла новая версия! ',
		messageLink: 'Обновите',
		messagePost: 'страницу',
	},
	cookies: {
		message:
			'Мы используем файлы cookie: технические, аналитические, маркетинговые и другие. ',
		acceptButton: 'Принять',
	},
	errors: {
		'nothing-here':
			'Мы тоже пытались найти эту страницу, но её просто не существует',
		unknownError: 'Непонятная ошибка',
	},
	menu: {
		title: 'Меню',
		Replenish: 'Пополнения',
		Transactions: 'Транзакции',
		Card: 'Анкеты',
		Salon: 'Салоны',
		Support: 'Поддержка',
		Profile: 'Профиль',
		Notifications: 'Уведомления',
		Favorites: 'Избранное',
	},
	name: 'Имя',
	yourName: 'Ваше имя',
	phone: 'Телефон',
	cartPage: {
		receipts: 'Квитанции',
		addBag: 'Добавить в корзину',
		total: 'Итого',
		goToPayment: 'Перейти к оплате',
		addItem: 'Добавить элемент',
		payOnCash: 'Оплатить на кассе',
		payByKaspi: 'Оплата через Каспи',
		receipt: 'Чек',
		thereIsNoItemsToShow: 'Тут ничего нет',
		yourOrderNumberIs: 'Номер вашего заказа',
		showThisNumberToCashierToMakePayment:
			'Покажите этот номер кассиру для оплаты.',
		stopPurchase: 'Остановить покупку',
		methodOfPayment: 'Метод оплаты',
		byCash: 'Наличными',
		listOfPurchase: 'Список покупок',
	},
	profilePage: {
		pleaseEnterCorrectNumber: 'Пожалуйста, введите правильный номер телефона',
		enterCorrectInformation: 'Пожалуйста, введите правильную информацию',
	},
	Search: 'Имя, описание',
	authStore: {
		emailSignUpSuccess: 'Письмо с данными для входа отправлено на {email}',
		emailSignUpFailed: 'Не удалось отправить письмо, свяжитесь с техподдержкой',
		signUpSuccess: 'Регистрация завершена',
		refreshFailed: 'Не удалось автоматически войти в систему',
		logout: 'Вы вышли из системы',
	},
	personCard: {
		phoneButtonTitle: 'Показать контакты',
		prices: {
			oneHour: '1 час',
			twoHour: '2 часа',
			night: 'Ночь',
			hasExpress: 'Есть экспресс',
		},
	},
	personPage: {
		showContacts: 'Показать телефон',
		p_unavailable:
			'Внимание! Данная анкета НЕДОСТУПНА или девушка временно не работает. Информация взята из архива.',
		cardNumber: 'Номер анкеты',
		descriptionMoreButton: 'Подробнее',
		prepaymentText: 'Данной девушке можно переводить предоплату в размере до',
		preferables: 'Предпочтения',
		comments: 'Отзывы',
		noComments: 'Пока нет отзывов',
		logInAndComment: 'Войти и оставить отзыв',
		newCommentTitle: 'Ваш комментарий',
		sendButton: 'Отправить',
		videoTitle: 'Видео',
		tariffTitle: 'Тариф',
		prepaymentCheckBlock:
			'Платежные реквизиты проверены. Если просит аванс, то отправляйте на карту, которая заканчивается на',
		cardsCount: 'Анкет на этом номере',
		cardsCountButton: 'Посмотреть',
		friendsTitle: 'Подружки',
		nearbyTitle: 'Проститутки рядом',
		lastActivityTime: 'Дата последней активности',
		healthTitle: 'Проверка здоровья',
		commentSent: 'Комментарий отправлен на модерацию',
	},
	salonPage: {
		timeSince: 'На сайте с',
		info: 'Информация о салоне',
		showPhone: 'Показать телефон',
		workingTime: 'Время работы',
		prices: 'Расценки',
		models: 'Девушки',
	},
	salonsPage: {
		title: 'Интим-салоны',
		in: 'в',
	},
	mainLayout: {
		bottom: {
			title: 'Сайт проституток Ашу это',
			block1: {
				title: 'Надежно',
				text: 'Только настоящие проверенные анкеты и объявления',
			},
			block2: {
				title: 'Удобно',
				text: 'Большой выбор проституток на любой вкус и цену',
			},
			block3: { title: 'Быстро', text: 'Рядом с Вами обязательно найдётся' },
			block4: {
				title: 'Безотказно',
				text: 'Номера проституток указаны на сайте и они ждут вашего звонка',
			},
			block5: {
				title: 'Круглосуточно',
				text: 'Работаем по выходным и праздникам 24 часа в сутки',
			},
		},
	},
	mainPage: {
		header: { title: 'Анкеты на Ashoo', online: 'онлайн' },
		allButton: 'Все',
		new: 'Новые анкеты на Ашу',
		top20: 'Топ-20',
		salons: 'Салоны',
		massage: 'Массажистки на Ashoo',
		p1: 'На сайте с проститутками можно найти любые анкеты девушек по параметрам (рост, вес, размер груди), цвету волос или предпочтениям в сексе. У нас представлен большой выбор проституток Москвы, готовых на все ради вашего удовольствия. У каждой девочки есть подробные анкеты с реальными фото, отзывами и координатами. Есть отдельные категории сайта по национальности, цвету волос, весу, возрасту. У нас можно найти элитную девочку, шлюху, согласную на анал, предоставляющую услугу минета. Есть и частные индивидуалки, готовые выехать в любой район твоего города.',
		p2: 'Есть отдельные категории сайта по национальности, цвету волос, весу, возрасту. У нас можно найти элитную девочку, шлюху, согласную на анал, предоставляющую услугу минета. Есть и частные индивидуалки, готовые выехать в любой район твоего города.',
		howto: {
			title: 'Как вызвать проститутку в Москве',
			p1: 'Для оказания интим услуг можно воспользоваться Telegram, WhatsApp, Viber или звонком на мобильный телефон. Номера проституток указаны в анкетах, там же есть ссылки на личные мессенджеры.',
			p2: 'На сайте гарантируется полная анонимность. Мы не сохраняем личные данные клиентов и не передаём их третьим лицам.',
		},
	},
	mainSidebar: {
		addCard: 'Добавить анкету',
		viewProfile: 'Профиль',
		signin: 'Войти',
		map: 'На карте',
		serviceList: 'Список услуг',
	},
	citySelector: {
		allCities: 'Все города',
		allDistricts: 'Все районы',
	},
	metroSelector: {
		search: 'Поиск станции',
		allStations: 'Все станции',
	},
	intimeMap: {
		title: 'Интим-карта',
		text: 'Удобный подбор предложений рядом с вами',
		button: 'Смотреть на карте',
	},
	personProperties: { tags: 'Теги' },
	notificationPage: {
		title: 'Уведомления',
		readAll: 'Прочитать всё',
		noNotifications: 'Нет уведомлений',
	},
	notificationBell: {
		noNotifications: 'Тут ничего нового',
		seeAll: 'Смотреть все',
	},
	personTariff: {
		day: 'Днём',
		night: 'Ночью',
		onUser: 'У меня',
		onCustomer: 'У тебя',
	},
	emailInput: 'Электронная почта',
	emailProvider: {
		verified: 'Подтвержден',
		notVerified: 'Почтовый адрес не подтвержден',
		sendAgain: 'Отправить письмо повторно',
		resetPassword: 'Сбросить пароль',
		notLinked: 'Электронная почта не привязана',
		link: 'Привязать email',
	},
	authProviders: { title: 'Способы авторизации', delete: 'Удалить' },
	profileEdit: { title: 'Профиль' },
	footer: {
		collapsedBtnText:
			'Удобный поиск проституток, массажисток и эскорт-моделей по всей России',
		description:
			'проект, созданный для удобного поиска проституток, массажисток и эскорт-моделей по всей России',
		links: {
			main: 'Главная',
			about: 'О проекте',
			advert: 'Реклама на сайте',
			founding: 'Фонд Ashoo',
			health: 'HEALTH+',
			metro: 'Метро',
			districts: 'Районы',
			roads: 'Шоссе',
			cities: 'Города',
			eulaCustomer: 'Пользовательское соглашение для клиентов',
			eulaUser: 'Пользовательское соглашение для рекламодателей',
			privacyRules: 'Политика конфиденциальности',
			contacts: 'Контакты',
		},
	},
	replenish: {
		btnTitle: 'Пополнить',
		title: 'Пополнения',
		filter: {
			statusLabel: 'Статус пополнения',
			minAmountLabel: 'Пополнение (от)',
			maxAmountLabel: 'Пополнение (до)',
			dateSelected: 'Даты выбраны',
			dateNotSelected: 'Даты не выбраны',
			apply: 'Применить',
		},
		statusLabel: 'Статус',
		providerLabel: 'Оператор',
		amountLabel: 'Сумма',
		feeLabel: 'Комиссия',
		usdAmountLabel: 'Сумма в USD',
		dateLabel: 'Дата',
		filterLabel: 'Фильтры',
		status: {
			approved: 'Подтверждено',
			new: 'Отправлено',
			rejected: 'Отклонено',
		},
		noFee: 'Без комиссии',
	},
	replenishNew: {
		btnTitle: 'Пополнить',
		title: 'Пополнение баланса',
		providerLabel: 'Платежный провайдер',
		amountLabel: 'Сумма',
		commentLabel: 'Комментарий',
		ruleExpansionLabel: 'Условия принятия платежа',
		rules: {
			minAmount: 'Минимальное пополнение',
			requisites: 'Реквизиты',
			text: 'Условия',
		},
		currencyLabel: 'Валюта пополнения',
		feeLabel: 'Комиссия',
		ruleCheckboxLabel: 'Я прочитал условия принятия платежа',
		submitBtnLabel: 'Отправить запрос на пополнение',
		fileLabel: 'Файл квитанции',
		validation: {
			minAmount: 'Сумма должна быть больше {min} {currency}',
			fileSize: 'Размер файла не должен превышать {size} Мб',
		},
		successText: 'Запрос на пополнение отправлен',
	},
	validation: {
		isNumber: 'Должно быть числом',
		gt: 'Должно быть больше {value}',
	},
	favoritesPage: {
		title: 'Избранное',
	},
}
