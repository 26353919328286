import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-client_id"]
const _hoisted_2 = ["data-type"]

import { useAuthStore } from 'src/stores/auth.store'

interface GoogleToken {
	clientId: string
	client_id: string
	credential: string
	select_by: 'btn'
}

type Props = {
	apiFn: (token: string) => Promise<unknown>
	mode: 'standard' | 'icon'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'google-auth-btn',
  props: {
    apiFn: { type: Function },
    mode: {}
  },
  emits: ['add'],
  setup(__props: any, { emit: __emit }) {

const authStore = useAuthStore()
const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID

const emit = __emit
const props = __props

const googleBtnLoginHandler = (token: GoogleToken) => {
	const jwtToken = token.credential
	authStore.googleToken = jwtToken
	props.apiFn(jwtToken).then(() => {
		emit('add', true)
	})
}

// add to global scope
;(globalThis as any).googleBtnLoginHandler = googleBtnLoginHandler

return (_ctx: any,_cache: any) => {
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createBlock(_component_q_no_ssr, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent("script"), {
        src: "https://accounts.google.com/gsi/client",
        async: ""
      })),
      _createElementVNode("div", {
        id: "g_id_onload",
        "data-client_id": _unref(GOOGLE_CLIENT_ID),
        "data-callback": "googleBtnLoginHandler",
        "data-auto_prompt": "false"
      }, null, 8, _hoisted_1),
      _createElementVNode("div", {
        class: "g_id_signin",
        "data-ux_mode": "popup",
        "data-type": props.mode,
        "data-size": "medium",
        "data-theme": "outline",
        "data-text": "sign_in_with",
        "data-shape": "rectangular",
        "data-logo_alignment": "left"
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}
}

})