import { I18nTranslation, TinyCity } from './common/axios-client'

export const SIDEBAR_NOTIFICATIONS_LIMIT = 10 as const

// Russia
export const DEFAULT_COUNTRY_ID = 185

// Moscow
export const DEFAULT_CITY = {
	id: 524901,
	slug: 'moskva',
	name: 'Москва',
	regionId: 524894,
	i18: {
		entityId: 524901,
		field: 'name',
		model: 'city',
		genitive: 'Москву',
		prepositional: 'Москве',
		text: 'Москва',
	},
} satisfies TinyCity & { i18: I18nTranslation }
