import script from "./password-input.vue?vue&type=script&setup=true&lang=ts"
export * from "./password-input.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/.pnpm/@quasar+app-webpack@3.13.4_eslint@8.57.0_pinia@2.2.2_typescript@4.9.4_vue@3.5.3_typescript@4._kdplrr3ceeinsy2ecobt4vztby/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn});
