import { Notify } from 'quasar'

export function showError(message: string) {
	Notify.create({
		color: 'red-5',
		textColor: 'white',
		iconColor: 'white',
		icon: 'error',
		timeout: 2000,
		message,
	})
	return message
}

export function showSuccess(msg: string) {
	Notify.create({
		color: 'green',
		textColor: 'white',
		icon: 'info',
		message: msg,
		timeout: 2000,
	})
}
