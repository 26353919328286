import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

type Props = {
	validation?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'email-input',
  props: {
    validation: { type: Boolean, default: true }
  },
  emits: ['input'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n()
const props = __props
const schema = z
	.string()
	.email({ message: 'Некорректный адрес электронной почты' })

const email = ref('')
const isValid = computed(() => schema.safeParse(email.value).success)
const error = computed(() =>
	schema.safeParse(email.value).error?.formErrors.formErrors.shift(),
)

const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: email.value,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
      _cache[1] || (_cache[1] = ($event: any) => (emit('input', email.value)))
    ],
    modelModifiers: { trim: true },
    label: _unref(t)('emailInput'),
    "bottom-slots": "",
    error: props.validation && email.value.length !== 0 && !isValid.value,
    "error-message": error.value
  }, null, 8, ["modelValue", "label", "error", "error-message"]))
}
}

})