import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex column map justify-center gap-15" }
const _hoisted_2 = { class: "flex title self-center" }
const _hoisted_3 = { class: "flex text self-center" }
const _hoisted_4 = { class: "flex text self-center" }

import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'intime-map',
  setup(__props) {

const { t } = useI18n()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('intimeMap.title')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('intimeMap.text')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_q_btn, {
        outline: "",
        class: "button",
        icon: "ion-globe",
        flat: "",
        label: _unref(t)('intimeMap.button')
      }, null, 8, ["label"])
    ])
  ]))
}
}

})