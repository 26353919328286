import { boot } from 'quasar/wrappers'
import axios, { Axios, AxiosError, AxiosInstance } from 'axios'
import { useAuthStore } from 'stores/auth.store'
import { useUiStore } from 'stores/ui.store'
import {
	AuthApi,
	BalanceReminingApi,
	BannersApi,
	CommentsApi,
	ContactsApi,
	CustomerProfileApi,
	FavoritesApi,
	I18nApi,
	ImageApi,
	MediaApi,
	MetaApi,
	NotificationsApi,
	PersonsApi,
	RateApi,
	RefsApi,
	ReplenishApi,
	SalonsApi,
	TransactionsApi,
	UserPersonFriendsApi,
	UserPersonPublishingApi,
	UserPersonReviewApi,
	UserPersonsApi,
	UserPersonVerificationApi,
	UserProfileApi,
	UserSalonPublishingApi,
	UserSalonsApi,
} from 'src/common/axios-client'

import { Headers } from '@interfaces/headers'
import { Mutex } from 'async-mutex'

import { BackendError, isStringError } from '@interfaces/error.interface'
import { showError } from 'src/functions/toast'

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$axios: Axios
		$api: AxiosInstance
	}
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
// const api = axios.create({ baseURL: process.env.API_URL })

export const baseURL = process.env.SERVER
	? process.env.PRIVATE_API_URL
	: process.env.API_URL

const api = axios.create({ baseURL })

export const API = {
	authApi: new AuthApi(undefined, '/', api),
	balanceReminingApi: new BalanceReminingApi(undefined, '/', api),
	bannersApi: new BannersApi(undefined, '/', api),
	commentsApi: new CommentsApi(undefined, '/', api),
	contactsApi: new ContactsApi(undefined, '/', api),
	customerProfileApi: new CustomerProfileApi(undefined, '/', api),
	favoritesApi: new FavoritesApi(undefined, '/', api),
	rateApi: new RateApi(undefined, '/', api),
	imageApi: new ImageApi(undefined, '/', api),
	mediaApi: new MediaApi(undefined, '/', api),
	metaApi: new MetaApi(undefined, '/', api),
	notificationsApi: new NotificationsApi(undefined, '/', api),
	personsApi: new PersonsApi(undefined, '/', api),
	salonsApi: new SalonsApi(undefined, '/', api),
	replenishApi: new ReplenishApi(undefined, '/', api),
	transactionsApi: new TransactionsApi(undefined, '/', api),
	userPersonFriendsApi: new UserPersonFriendsApi(undefined, '/', api),
	userPersonPublishingApi: new UserPersonPublishingApi(undefined, '/', api),
	userPersonReviewApi: new UserPersonReviewApi(undefined, '/', api),
	userPersonVerificationApi: new UserPersonVerificationApi(undefined, '/', api),
	userPersonsApi: new UserPersonsApi(undefined, '/', api),
	userSalonPublishingApi: new UserSalonPublishingApi(undefined, '/', api),
	userSalonsApi: new UserSalonsApi(undefined, '/', api),
	userProfileApi: new UserProfileApi(undefined, '/', api),
	refsApi: new RefsApi(undefined, '/', api),
	i18n: new I18nApi(undefined, '/', api),
} as const

export default boot(({ app }) => {
	// for use inside Vue files (Options API) through this.$axios and this.$api

	app.config.globalProperties.$axios = axios
	// ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
	//       so you won't necessarily have to import axios in each vue file

	app.config.globalProperties.$api = api
	// ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
	//       so you can easily perform requests against your app's API

	const authStore = useAuthStore()
	const uiStore = useUiStore()

	const mutex = new Mutex()

	const getLanguage = (): string => {
		if (authStore.user) {
			return authStore.user.info.lang
		}

		return uiStore.locale
	}

	api.interceptors.request.use(
		async (config) => {
			if (authStore.user)
				config.headers.set(
					Headers.authorization,
					`Bearer ${authStore.user.tokens.accessToken}`,
				)

			config.headers.set(Headers.language, getLanguage())

			return config
		},
		(error) => {
			void Promise.reject(error)
		},
	)

	// Response interceptor for API calls
	api.interceptors.response.use(
		(response) => {
			if (response.data.error) throw showError(response.data.error)
			return response
		},
		async (error: AxiosError<BackendError>) => {
			const { response, config: originalRequest } = error

			if (!response) {
				// backend down
				throw showError(
					// TODO: i18n
					'Сервер недоступен',
				)
			}

			const { status } = response

			if (originalRequest && originalRequest.headers.retry) {
				await mutex.waitForUnlock()
				return api(originalRequest)
			}

			if (
				status === 401 &&
				authStore.user?.tokens?.refreshToken &&
				originalRequest
			) {
				const locked = mutex.isLocked()
				if (locked) {
					originalRequest.headers.retry = true
					await mutex.waitForUnlock()
					return api(originalRequest)
				}

				/** try to refresh tokens */
				originalRequest.headers.retry = true
				const release = await mutex.acquire()

				await authStore.refreshAccessToken().finally(() => {
					release()
				})

				return api(originalRequest)
			}

			if (error.response?.data?.message) {
				const message = error.response?.data?.message
				if (isStringError(message)) throw showError(message)
				throw showError('Ошибка валидации')
			}
			throw showError(`Error ${error.status}: ${error.message}`)
		},
	)
})

export { api }
