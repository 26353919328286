import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useFavoriteStore } from 'src/stores/favorite.store'


export default /*@__PURE__*/_defineComponent({
  __name: 'favorite-counter',
  setup(__props) {

const favoriteStore = useFavoriteStore()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    rounded: "",
    flat: "",
    dense: "",
    "fab-mini": "",
    icon: _unref(favoriteStore).count() ? 'favorite' : 'favorite_outline',
    style: {"color":"var(--q-favorite-color)"},
    to: _unref(favoriteStore).count() ? '/favorites' : undefined,
    label: _unref(favoriteStore).count() ? _unref(favoriteStore).favoriteLabel : undefined
  }, null, 8, ["icon", "to", "label"]))
}
}

})