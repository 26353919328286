import script from "./register-form.vue?vue&type=script&setup=true&lang=ts"
export * from "./register-form.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/.pnpm/@quasar+app-webpack@3.13.4_eslint@8.57.0_pinia@2.2.2_typescript@4.9.4_vue@3.5.3_typescript@4._kdplrr3ceeinsy2ecobt4vztby/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QCardSection,QInput,QSelect,QBtnToggle,QCardActions,QBtn});
