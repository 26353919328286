import { api } from 'boot/axios'
import { Notify, useInterval } from 'quasar'
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

interface IReleaseInfo {
	version?: string
	release?: string
}

export function useUpdateChecker() {
	const i18n = useI18n()
	const { registerInterval, removeInterval } = useInterval()

	const releaseInfo = ref<IReleaseInfo>({
		release: process.env.RELEASE,
		version: process.env.VERSION,
	})

	async function checkForUpdates(): Promise<boolean> {
		const { data } = await api.get<IReleaseInfo>(
			`/release-info.json?${Date.now()}`,
			{ baseURL: '/' },
		)

		return (
			data.release !== undefined && releaseInfo.value.release !== data.release
		)
	}

	function showMessage(): void {
		const message = `${i18n.t('updater.messagePre')}
			<a href="" class="notify-info">${i18n.t('updater.messageLink')}</a>
			${i18n.t('updater.messagePost')}
			`
		Notify.create({
			message,
			html: true,
			type: 'info',
		})
	}

	onMounted(() => {
		const updateInterval = process.env.NODE_ENV === 'production' ? 60000 : 10000

		registerInterval(() => {
			checkForUpdates().then((hasUpdate) => {
				if (!hasUpdate) return
				showMessage()
			})
		}, updateInterval)
	})

	onUnmounted(() => {
		removeInterval()
	})
}
