import { defineStore, storeToRefs } from 'pinia'
import {
	EmailSignInInput,
	EmailSignUpInput,
	GoogleSignUpInput,
	TelegramProviderCreateInput,
	TelegramSignUpInput,
	Tokens,
} from 'src/common/axios-client'
import { API } from 'boot/axios'
import { showError, showSuccess } from '../functions/toast'
import { ref } from 'vue'
import { UserJwt } from '@interfaces/jwt.interface'
import { jwtDecode } from 'jwt-decode'
import { useUiStore } from './ui.store'

interface User {
	tokens: Tokens
	info: UserJwt
}

export const useAuthStore = defineStore(
	'authStore',
	() => {
		const user = ref<User>()
		const telegramData = ref<TelegramProviderCreateInput | null>(null)
		const googleToken = ref<string | null>(null)

		const { i18n } = storeToRefs(useUiStore())

		async function logout() {
			user.value = undefined
			telegramData.value = null
			googleToken.value = null
			showSuccess(i18n.value.t('authStore.logout'))
		}

		async function loginByEmail({
			login,
			password,
		}: EmailSignInInput): Promise<void> {
			API.authApi
				.emailControllerSignIn({
					login,
					password,
				})
				.then((res) => setTokens(res.data))
		}

		async function registerByEmail(input: EmailSignUpInput) {
			return API.authApi
				.emailControllerSignUp(input, process.env.DOMAIN)
				.then(() => {
					showSuccess(
						i18n.value.t('authStore.emailSignUpSuccess', {
							email: input.login,
						}),
					)
				})
				.catch(() => {
					showError(i18n.value.t('authStore.emailSignUpFailed'))
				})
		}

		async function registerByGoogle(input: GoogleSignUpInput) {
			return API.authApi.googleControllerSignUp(input).then(async (r) => {
				await setTokens(r.data)
				showSuccess(i18n.value.t('authStore.signUpSuccess'))
			})
		}

		async function registerByTelegram(input: TelegramSignUpInput) {
			return API.authApi.telegramControllerSignUp(input).then(async (r) => {
				await setTokens(r.data)
				showSuccess(i18n.value.t('authStore.signUpSuccess'))
			})
		}

		async function setTokens(tokens: Tokens) {
			user.value = {
				tokens,
				info: jwtDecode<UserJwt>(tokens.accessToken),
			}
		}

		async function refreshAccessToken(): Promise<void> {
			if (!user.value) {
				await logout()
				return
			}

			await API.authApi
				.authControllerRefresh({
					refreshToken: user.value.tokens.refreshToken,
				})
				.then((res) => setTokens(res.data))
				.catch(async () => {
					await logout()
					showError(i18n.value.t('authStore.refreshFailed'))
				})
		}

		return {
			user,
			telegramData,
			googleToken,
			refreshAccessToken,
			loginByEmail,
			registerByEmail,
			registerByGoogle,
			registerByTelegram,
			setTokens,
			logout,
		}
	},
	{ persist: true },
)
